import { useState } from "react";

function useHasSubmitted() {
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  const handleOnError = () => {
    setHasSubmitted(true);
  };

  const resetHasSubmitted = () => {
    setHasSubmitted(false);
  };

  return { hasSubmitted, handleOnError, resetHasSubmitted };
}

export default useHasSubmitted;
