import {
  CreditorType,
  UploadedDocumentType,
} from "@gocardless/api/dashboard/types";
import {
  Box,
  Button,
  ButtonVariant,
  Form,
  H3,
  P,
  Separator,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { DocumentRequirements } from "src/components/routes/Setup/optimised-verification-status/common/DocumentRequirements";
import { HavingTroubleDialog } from "src/components/routes/Setup/optimised-verification-status/common/HavingTroubleDialog";
import {
  CHARITY_DOCUMENTS,
  COMPANY_OR_PARTNERSHIP_DOCUMENTS,
  INDIVIDUAL_DOCUMENTS,
} from "src/components/routes/Setup/optimised-verification-status/config/HighRiskConfig";
import {
  DocumentRequirement,
  UploadDocOnlyFormValues,
} from "src/components/routes/Setup/optimised-verification-status/constants/DocumentRequirement";
import { AcceptedDocuments } from "src/components/routes/Setup/optimised-verification-status/common/AcceptedDocuments";
import { useUploadDocuments } from "src/components/routes/Setup/optimised-verification-status/required-documents/useUploadDocuments";
import { RequiredActionCardProps } from "src/components/routes/Setup/optimised-verification-status/required-actions/RequiredActionCard";
import { useForm } from "react-hook-form";

import FormError from "../../common/FormError";
import { useUploadDocValidation } from "../../hooks/useUploadDocValidations";
import { ControlledFileUpload } from "../../common/ControlledFileUpload";

export const HighRiskDocumentUpload: React.FC<RequiredActionCardProps> = ({
  creditorId,
  creditorType,
  verificationId,
}) => {
  const { uploading, uploadDocuments } = useUploadDocuments(
    creditorId,
    verificationId
  );

  const { validateFilesMinAndMaxWithSpecificCondition } =
    useUploadDocValidation({
      condition: creditorType !== CreditorType.Individual,
      minFilesRequired: 2,
    });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UploadDocOnlyFormValues>({
    defaultValues: {
      upload_docs: [],
    },
  });

  const uploadSelectedDocuments = async (value: UploadDocOnlyFormValues) => {
    await uploadDocuments(
      value.upload_docs,
      UploadedDocumentType.FinancialStatement
    );
  };

  const months = 12;

  return (
    <Form onSubmit={handleSubmit(uploadSelectedDocuments)}>
      {creditorType === CreditorType.Individual ? (
        <>
          <Box>
            <H3 preset={TypePreset.Heading_02} spaceBelow={1}>
              <Trans>Financial position</Trans>
            </H3>
            <P preset={TypePreset.Body_01}>
              <Trans>
                Please upload a document that details your financial situation.
                This must be dated from <b>within the last {months} months</b>
              </Trans>
            </P>
            {INDIVIDUAL_DOCUMENTS[0] && (
              <Box spaceAbove={1}>
                <AcceptedDocuments documents={INDIVIDUAL_DOCUMENTS[0]} />
              </Box>
            )}
          </Box>
        </>
      ) : creditorType === CreditorType.Charity ? (
        <>
          <Box>
            <H3 preset={TypePreset.Heading_02} spaceBelow={1}>
              1. <Trans>Beneficial owners</Trans>
            </H3>
            <P preset={TypePreset.Body_01}>
              <Trans>
                Please upload an official document that confirms the following
                information:
              </Trans>
            </P>
            <Box spaceAbove={1}>
              <DocumentRequirements
                requirements={[DocumentRequirement.CurrentBeneficialOwners]}
              />
            </Box>
            {CHARITY_DOCUMENTS[0] && (
              <Box spaceAbove={1}>
                <AcceptedDocuments documents={CHARITY_DOCUMENTS[0]} />
              </Box>
            )}
          </Box>
          <Separator spacing={[[2, 0]]} />
          <Box>
            <H3 preset={TypePreset.Heading_02} spaceBelow={1}>
              2. <Trans>Financial position</Trans>
            </H3>
            <P preset={TypePreset.Body_01}>
              <Trans>
                Please upload a document that details your financial situation.
                This must be dated from <b>within the last {months} months</b>
              </Trans>
            </P>
            {CHARITY_DOCUMENTS[1] && (
              <Box spaceAbove={1}>
                <AcceptedDocuments documents={CHARITY_DOCUMENTS[1]} />
              </Box>
            )}
          </Box>
        </>
      ) : (
        <>
          <Box>
            <H3 preset={TypePreset.Heading_02} spaceBelow={1}>
              1. <Trans>Business owners</Trans>
            </H3>
            <P preset={TypePreset.Body_01}>
              <Trans>
                Please upload an official document that confirms the following
                information:
              </Trans>
            </P>
            <Box spaceAbove={1}>
              <DocumentRequirements
                requirements={[
                  DocumentRequirement.CurrentUltimateBeneficialOwners,
                ]}
              />
            </Box>
            {COMPANY_OR_PARTNERSHIP_DOCUMENTS[0] && (
              <Box spaceAbove={1}>
                <AcceptedDocuments
                  documents={COMPANY_OR_PARTNERSHIP_DOCUMENTS[0]}
                />
              </Box>
            )}
          </Box>
          <Separator spacing={[[2, 0]]} />
          <Box>
            <H3 preset={TypePreset.Heading_02} spaceBelow={1}>
              2. <Trans>Financial position</Trans>
            </H3>
            <P preset={TypePreset.Body_01}>
              <Trans>
                Please upload a document that details your financial situation.
                This must be dated from <b>within the last {months} months</b>
              </Trans>
            </P>
            {COMPANY_OR_PARTNERSHIP_DOCUMENTS[1] && (
              <Box spaceAbove={1}>
                <AcceptedDocuments
                  documents={COMPANY_OR_PARTNERSHIP_DOCUMENTS[1]}
                />
              </Box>
            )}
          </Box>
        </>
      )}

      <Separator spacing={[[2, 0]]} />

      {creditorType !== CreditorType.Individual && (
        <H3 preset={TypePreset.Heading_02}>
          <Trans>Please upload both documents</Trans>
        </H3>
      )}

      <Box spaceAbove={2}>
        <ControlledFileUpload
          required
          name="upload_docs"
          control={control}
          uploading={uploading}
          validationRules={validateFilesMinAndMaxWithSpecificCondition}
        />
      </Box>
      {errors.upload_docs?.message ? (
        <FormError errorMessage={errors.upload_docs?.message} />
      ) : null}
      <Box spaceAbove={2}>
        <Button
          type="submit"
          variant={ButtonVariant.PrimaryOnLight}
          disabled={uploading}
        >
          <Trans>Upload</Trans>
        </Button>
      </Box>
      <HavingTroubleDialog sourceComponent={HighRiskDocumentUpload.name} />
    </Form>
  );
};
