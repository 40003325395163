import { CreditorType, DetailResource } from "@gocardless/api/dashboard/types";

import { getPersonName } from "./getPersonName";

export interface BankAccountHolderDetails {
  isIndividual: boolean;
  creditorName?: string;
  tradingName?: string;
  personName?: string;
}

export const getBankAccountHolderDetails = (
  creditorType: CreditorType,
  detail: DetailResource
): BankAccountHolderDetails => {
  if (creditorType === CreditorType.Individual) {
    return {
      isIndividual: true,
      tradingName: detail.trading_name || undefined,
      personName: detail.person ? getPersonName(detail.person) : undefined,
    };
  }
  return { isIndividual: false, creditorName: detail.name || undefined };
};
