import {
  Box,
  ButtonLayout,
  ColorPreset,
  ColorScheme,
  Upload,
  UploadSelectionMode,
  UploadStatus,
} from "@gocardless/flux-react";
import { t } from "@lingui/macro";
import { useMemo } from "react";
import { useLingui } from "@lingui/react";

const MAX_FILE_SIZE = 15;
const MIN_FILE_SIZE = 5;

export const ChooseFiles: React.FC<{
  files: File[];
  onChange: (files: File[]) => void;
  uploading: boolean;
  multiple?: boolean;
}> = ({ files, onChange, uploading, multiple = false }) => {
  const { i18n } = useLingui();
  const fileErrors = useMemo(
    () =>
      files
        .filter(
          (file) =>
            file.size < MIN_FILE_SIZE * 1024 ||
            file.size > MAX_FILE_SIZE * 1024 * 1024
        )
        .map((file) => ({
          fileName: file.name,
          message: i18n._(
            t({
              id: "choose-files.file-size-error",
              message: `Invalid file size, must be between ${MIN_FILE_SIZE} KB and ${MAX_FILE_SIZE} MB`,
            })
          ),
        })),
    [files, i18n]
  );

  return (
    <Box className="fs-mask">
      <Upload
        data-testid="upload-document"
        colorScheme={ColorScheme.OnLight}
        bg={ColorPreset.BackgroundLight_01}
        buttonLayout={ButtonLayout.Inline}
        accept=".jpg,.jpeg,.png,.heic,.pdf"
        errors={fileErrors?.map((error) => error.message)}
        selectionMode={
          multiple ? UploadSelectionMode.Multiple : UploadSelectionMode.Single
        }
        status={uploading ? UploadStatus.Uploading : UploadStatus.Initial}
        onSelectFiles={onChange}
        translations={{
          chooseFile: i18n._(t`Choose files`),
          removeFile:
            files.length > 1 ? i18n._(t`Remove files`) : i18n._(t`Remove file`),
          uploadingLabel:
            files.length > 1
              ? i18n._(t`Uploading files`)
              : i18n._(t`Uploading file`),
        }}
        description={i18n._(
          t`We support files (up to ${MAX_FILE_SIZE} MB) in PDF or image formats`
        )}
      />
    </Box>
  );
};
