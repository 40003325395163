import { RequiredActionsStatus } from "@gocardless/api/dashboard/types";
import { Color, Glyph, Icon } from "@gocardless/flux-react";
import { useMemo } from "react";
import Sentry from "src/technical-integrations/sentry/sentry";
import { Image } from "src/components/images";

const PendingIcon = () => (
  <Icon
    name={Glyph.ExclamationCircle}
    color={Color.Warning_300}
    size="24px"
    verticalAlign="middle"
  />
);
const InReviewIcon = () => <Image width="24px" name="VerificationInReview" />;
const SuccessfulIcon = () => (
  <Icon
    name={Glyph.TickCircle}
    color={Color.Success_300}
    size="24px"
    verticalAlign="middle"
  />
);
const UnsuccessfulIcon = () => (
  <Icon
    name={Glyph.CloseCircleLight}
    color={Color.Alert_500}
    size="24px"
    verticalAlign="middle"
  />
);

const statusMapping = {
  [RequiredActionsStatus.Pending]: PendingIcon,
  [RequiredActionsStatus.PendingResponse]: PendingIcon,
  [RequiredActionsStatus.InReview]: InReviewIcon,
  [RequiredActionsStatus.InAutomaticReview]: InReviewIcon,
  [RequiredActionsStatus.Processing]: InReviewIcon,
  [RequiredActionsStatus.Successful]: SuccessfulIcon,
  [RequiredActionsStatus.Unsuccessful]: UnsuccessfulIcon,
  [RequiredActionsStatus.Cancelled]: null,
  [RequiredActionsStatus.CouldNotVerify]: null,
  [RequiredActionsStatus.Superseded]: null,
};

export const RequiredActionStatusIcon: React.FC<{
  status: RequiredActionsStatus;
}> = ({ status }) => {
  const IconComponent = useMemo(() => statusMapping[status], [status]);

  if (!IconComponent) {
    Sentry.captureMessage(
      `No RequiredActionStatusIcon component for status '${status}'`
    );
    return null;
  }

  return <IconComponent />;
};
