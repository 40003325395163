import {
  ButtonSize,
  ButtonVariant,
  Dialog,
  H2,
  H4,
  Link,
  P,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { getRouteURL, Route } from "src/common/routing";

import { BankAccountHolderDetails } from "../../helpers/getBankAccountHolderDetails";

export const BankAccountHolderDialog = ({
  bankAccountHolderDetails: {
    isIndividual,
    creditorName: cName,
    personName: pName,
    tradingName: tName,
  },
  onClose,
}: {
  bankAccountHolderDetails: BankAccountHolderDetails;
  onClose: () => void;
}) => {
  const creditorName = <Text className="fs-mask">{cName}</Text>;
  const personName = <Text className="fs-mask">{pName}</Text>;
  const tradingName = <Text className="fs-mask">{tName}</Text>;

  const addBankAccountLink = (
    <Link
      href={getRouteURL({
        route: Route.BankDetails,
      })}
      variant={ButtonVariant.InlineUnderlined}
      size={ButtonSize.Md}
    >
      <Trans>add a different bank account</Trans>
    </Link>
  );

  const amendBusinessDetailsLink = (
    <Link
      href={getRouteURL({
        route: Route.BusinessDetails,
      })}
      variant={ButtonVariant.InlineUnderlined}
      size={ButtonSize.Md}
    >
      <Trans>amend your business details</Trans>
    </Link>
  );

  return (
    <Dialog
      open
      bodyGutterH={2}
      bodyGutterV={2}
      closeAction={{
        label: <Trans>Close</Trans>,
        onClose,
      }}
      header={
        <H2 preset={TypePreset.Heading_02}>
          <Trans>Bank account name: what we can accept</Trans>
        </H2>
      }
    >
      <H4 preset={TypePreset.Body_02} spaceAbove={0} spaceBelow={1}>
        {isIndividual ? (
          <>
            {tName ? (
              <Trans>
                The bank account name on the document must exactly match your
                full name ({personName}) or trading name ({tradingName})
              </Trans>
            ) : (
              <Trans>
                The bank account name on the document must exactly match your
                full name ({personName})
              </Trans>
            )}
          </>
        ) : (
          <Trans>
            The bank account name on the document must exactly match your
            organisation name ({creditorName})
          </Trans>
        )}
      </H4>

      <P preset={TypePreset.Body_01}>
        <Trans>
          We need the name of the bank account to match the business details
          you&apos;ve provided so we can verify it for use with this account. If
          your bank account is under a different name, you may wish to{" "}
          {addBankAccountLink} or {amendBusinessDetailsLink}.
        </Trans>
      </P>

      <H4 preset={TypePreset.Body_02} spaceAbove={1} spaceBelow={1}>
        <Trans>
          We need to see the full account holder name on the document
        </Trans>
      </H4>

      <P preset={TypePreset.Body_01}>
        <Trans>
          For example, if the business name on your GoCardless account is
          “Bloggs Consulting” we will not able to accept a truncated name such
          as “Bloggs Consu”.
        </Trans>
      </P>

      {isIndividual ? null : (
        <>
          <H4 preset={TypePreset.Body_02} spaceAbove={1} spaceBelow={1}>
            <Trans>
              If your business name includes a suffix such as “Limited” or
              “LLC”, this should be visible on the document
            </Trans>
          </H4>

          <P preset={TypePreset.Body_01}>
            <Trans>
              For example, if the business name is “Bloggs Consulting Ltd”, we
              would not be able to accept “Bloggs Consulting”.
            </Trans>
          </P>
        </>
      )}
    </Dialog>
  );
};
