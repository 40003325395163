import { CountryCodes } from "src/common/country";
import { AcceptedDocuments } from "src/components/routes/Setup/common/constants/AcceptedDocuments";

import { DocumentRequirement } from "../constants/DocumentRequirement";
import { CharityConfirmationCard } from "../required-actions/charity-confirmation/CharityConfirmationCard";
import { RequiredDocumentStatus } from "../status-cards/status-item/RequiredDocumentStatus";

export const CHARITY_ACCEPTED_DOCUMENTS_DEFAULT = [
  AcceptedDocuments.CharityRegisterExtract3Months,
];

const CHARITY_ACCEPTED_DOCUMENTS_COUNTRY_MAP: Record<
  string,
  AcceptedDocuments[]
> = {
  [CountryCodes.DE]: [AcceptedDocuments.CharityRegisterExtract3Months],
  [CountryCodes.ES]: [AcceptedDocuments.CharityRegisterExtract12Months],
  [CountryCodes.FR]: [
    AcceptedDocuments.CharityRegisterExtract3Months,
    AcceptedDocuments.AnnualGeneralMeetingMinutes,
  ],
  [CountryCodes.IE]: [AcceptedDocuments.CharityRegisterExtract3Months],
};

export const CHARITY_DOCUMENT_REQUIREMENTS_DEFAULT = [
  DocumentRequirement.CharityName,
  DocumentRequirement.RegistrationNumber,
  DocumentRequirement.RegisteredAddress,
  DocumentRequirement.LegalRepresentatives,
];

export const CharityConfig = {
  acceptedDocuments: (geo: CountryCodes) =>
    CHARITY_ACCEPTED_DOCUMENTS_COUNTRY_MAP[geo] ||
    CHARITY_ACCEPTED_DOCUMENTS_DEFAULT,

  documentRequirements: (_geo: CountryCodes) =>
    CHARITY_DOCUMENT_REQUIREMENTS_DEFAULT,

  statusComponent: RequiredDocumentStatus,

  actionComponent: CharityConfirmationCard,
};
