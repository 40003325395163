import {
  Box,
  ColorPreset,
  H4,
  LI,
  TypePreset,
  UL,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  DocumentRequirement,
  DocumentRequirementsNameMap,
} from "src/components/routes/Setup/optimised-verification-status/constants/DocumentRequirement";

export const DocumentRequirements = ({
  requirements,
}: {
  requirements: DocumentRequirement[];
}) => {
  const { i18n } = useLingui();
  const DOCUMENT_REQUIREMENTS_NAME_MAP = DocumentRequirementsNameMap(i18n);

  return (
    <Box>
      <H4
        spaceBelow={0.5}
        preset={TypePreset.Body_01}
        color={ColorPreset.TextOnLight_01}
      >
        <Trans>The uploaded document must confirm the following:</Trans>
      </H4>
      <UL preset={TypePreset.Body_01} color={ColorPreset.TextOnLight_01}>
        {requirements.map((item) => (
          <LI key={item}>{DOCUMENT_REQUIREMENTS_NAME_MAP[item]}</LI>
        ))}
      </UL>
    </Box>
  );
};
