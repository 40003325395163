import {
  requiredActionAttachDocument,
  requiredActionConfirmDeposit,
} from "@gocardless/api/dashboard/required-action";
import { Document } from "@gocardless/api/dashboard/types";
import { useSWRConfig } from "swr";

export const useConfirmDeposit = () => {
  const { mutate } = useSWRConfig();

  return async (creditorId: string, actionId: string) => {
    const result = await requiredActionConfirmDeposit(actionId, {});

    await mutate(`required_actions`);
    await mutate(`creditors/${creditorId}`);

    return result;
  };
};

export const useAttachDocuments = () => {
  const { mutate } = useSWRConfig();

  return async (
    creditorId: string,
    actionId: string,
    documents: Document[]
  ) => {
    const result = await requiredActionAttachDocument(actionId, documents);

    await mutate(`required_actions`);
    await mutate(`creditors/${creditorId}`);

    return result;
  };
};
