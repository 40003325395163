import _ from "lodash";

export const getPersonName = ({
  given_name,
  family_name,
}: {
  given_name?: string;
  family_name?: string;
}) => {
  const joinedNames = [given_name, family_name].filter(Boolean).join(" ");

  return _.truncate(joinedNames, { length: 25 });
};
