import { DetailResource } from "@gocardless/api/dashboard/types";

export interface CreditorDetails {
  name: string;
  address: string;
  entity_type: string;
  entity_number: string;
}

const renderAddress = (detail: DetailResource): string =>
  [
    detail.address_line1,
    detail.address_line2,
    detail.address_line3,
    detail.city,
    detail.region,
    detail.postal_code,
  ]
    .filter(Boolean)
    .join(", ");

export const getCompanyDetails = (detail: DetailResource): CreditorDetails => ({
  name: detail.name || "",
  address: renderAddress(detail),
  entity_type: detail.creditor_subtype || "",
  entity_number: detail.company_number || "",
});

export const getCharityDetails = (detail: DetailResource): CreditorDetails => ({
  name: detail.name || "",
  address: renderAddress(detail),
  entity_type: detail.creditor_subtype || "",
  entity_number: detail.charity_number || "",
});

type FormattedEntityType =
  | "KG"
  | "oHG"
  | "GbR"
  | "GmbH & Co. KG / UG & Co. KG"
  | "";
const entityTypeMap: Record<string, FormattedEntityType> = {
  kg: "KG",
  ohg: "oHG",
  gbr: "GbR",
  blended: "GmbH & Co. KG / UG & Co. KG",
};

export const formatEntitySubType = (
  creditor_subtype: string | null | undefined
) => (creditor_subtype ? entityTypeMap[creditor_subtype.toLowerCase()] : "");

export const getPartnershipDetails = (
  detail: DetailResource
): CreditorDetails => {
  const { creditor_subtype } = detail;
  const formattedEntityType = formatEntitySubType(creditor_subtype);

  return {
    name: detail.name || "",
    address: renderAddress(detail),
    entity_type: formattedEntityType ?? "",
    entity_number: detail.partnership_number || "",
  };
};
