import { HTTPError } from "@gocardless/api/utils/api";
import {
  CreditorResource,
  CreditorDetailResource,
  CreditorUpdateResponseBody,
  CreditorDetailUpdateResponseBody,
} from "@gocardless/api/dashboard/types";
import { usePrimaryCreditor } from "src/queries/creditor";
import {
  CreditorUpdateRequestBody,
  useCreditorUpdate,
} from "@gocardless/api/dashboard/creditor";
import {
  CreditorDetailUpdateRequestBody,
  useCreditorDetailSelf,
  useCreditorDetailUpdate,
} from "@gocardless/api/dashboard/creditor-detail";

interface UseSetupData {
  loaded: boolean;
  creditor: CreditorResource | null | undefined;
  creditorDetails: CreditorDetailResource | undefined;
  updateCreditor: (
    b?: CreditorUpdateRequestBody | undefined
  ) => Promise<CreditorUpdateResponseBody | undefined>;
  updateCreditorDetail: (
    b?: CreditorDetailUpdateRequestBody | undefined
  ) => Promise<CreditorDetailUpdateResponseBody | undefined>;
}

export const useSetupData = (
  onSuccess = () => {},
  onError: (error: HTTPError) => void = () => {}
): UseSetupData => {
  const creditor = usePrimaryCreditor();
  const [updateCreditor] = useCreditorUpdate(creditor?.id || "");
  const { data: creditorDetails, revalidate } = useCreditorDetailSelf(
    creditor?.id || null
  );
  const [updateCreditorDetail] = useCreditorDetailUpdate(creditor?.id || "", {
    onSuccess: () => {
      revalidate().then(() => onSuccess());
    },
    onError,
  });

  return {
    loaded: !!creditor && !!creditorDetails,
    creditor,
    updateCreditor,
    creditorDetails: creditorDetails?.creditor_details,
    updateCreditorDetail,
  };
};
