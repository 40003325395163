/* modified by ui-hub */
import loadable from "@loadable/component";

const registry = {
  ConnectedApps: loadable(
    async () =>
      (await import("../../assets/svg/connected-apps.svg")).ReactComponent
  ),
  CustomBuiltApps: loadable(
    async () =>
      (await import("../../assets/svg/custom-built-apps.svg")).ReactComponent
  ),
  GoCardlessDashboard: loadable(
    async () =>
      (await import("../../assets/svg/gocardless-dashboard.svg")).ReactComponent
  ),
  Lock: loadable(
    async () => (await import("../../assets/svg/lock.svg")).ReactComponent
  ),
  WiseLogo: loadable(
    async () => (await import("../../assets/svg/wise.svg")).ReactComponent
  ),
  Phone: loadable(
    async () => (await import("../../assets/svg/phone.svg")).ReactComponent
  ),
  DirectDebitLogo: loadable(
    async () =>
      (await import("../../assets/svg/direct-debit-logo.svg")).ReactComponent
  ),
  MobileChat: loadable(
    async () =>
      (await import("../../assets/svg/mobile-chat.svg")).ReactComponent
  ),
  TabletMobile: loadable(
    async () =>
      (await import("../../assets/svg/tablet-mobile.svg")).ReactComponent
  ),
  ChangeCircle: loadable(
    async () =>
      (await import("../../assets/svg/change-circle.svg")).ReactComponent
  ),
  ArrowDown: loadable(
    async () => (await import("../../assets/svg/arrow-down.svg")).ReactComponent
  ),
  DevTerminal: loadable(
    async () =>
      (await import("../../assets/svg/dev-terminal.svg")).ReactComponent
  ),
  Switches: loadable(
    async () => (await import("../../assets/svg/switches.svg")).ReactComponent
  ),
  ClipboardTick: loadable(
    async () =>
      (await import("../../assets/svg/clipboard-tick.svg")).ReactComponent
  ),
  Chart: loadable(
    async () => (await import("../../assets/svg/Chart.svg")).ReactComponent
  ),
  EventSearch: loadable(
    async () =>
      (await import("../../assets/svg/EventSearch.svg")).ReactComponent
  ),
  Security: loadable(
    async () => (await import("../../assets/svg/Security.svg")).ReactComponent
  ),
  PadlockWhite: loadable(
    async () =>
      (await import("../../assets/svg/PadlockWhite.svg")).ReactComponent
  ),
  CustomerHeart: loadable(
    async () =>
      (await import("../../assets/svg/customer-heart.svg")).ReactComponent
  ),
  UtilityDocument: loadable(
    async () =>
      (await import("../../assets/svg/utility-document.svg")).ReactComponent
  ),
  EditPage: loadable(
    async () => (await import("../../assets/svg/edit-page.svg")).ReactComponent
  ),
  Refresh: loadable(
    async () => (await import("../../assets/svg/refresh.svg")).ReactComponent
  ),
  Clipboard: loadable(
    async () =>
      (await import("../../assets/svg/illustration-clipboard.svg"))
        .ReactComponent
  ),
  Flexible: loadable(
    async () =>
      (await import("../../assets/svg/illustration-flexible.svg"))
        .ReactComponent
  ),
  Padlock: loadable(
    async () =>
      (await import("../../assets/svg/illustration-padlock.svg")).ReactComponent
  ),
  DataGraph: loadable(
    async () =>
      (await import("../../assets/svg/illustration-data-graph.svg"))
        .ReactComponent
  ),
  Secure: loadable(
    async () =>
      (await import("../../assets/svg/illustration-secure.svg")).ReactComponent
  ),
  HandCash: loadable(
    async () =>
      (await import("../../assets/svg/illustration-hand-cash.svg"))
        .ReactComponent
  ),
  Dashboard: loadable(
    async () =>
      (await import("../../assets/svg/illustration-dashboard.svg"))
        .ReactComponent
  ),
  SwitchesIcon: loadable(
    async () =>
      (await import("../../assets/svg/illustration-switches.svg"))
        .ReactComponent
  ),
  ReduceChurn: loadable(
    async () =>
      (await import("../../assets/svg/reduce-churn.svg")).ReactComponent
  ),
  ToolTip: loadable(
    async () => (await import("../../assets/svg/tool-tip.svg")).ReactComponent
  ),
  Collection: loadable(
    async () => (await import("../../assets/svg/collection.svg")).ReactComponent
  ),
  GCLoadingIndicatorBackground: loadable(
    async () =>
      (await import("../../assets/svg/gc-loading-indicator-background.svg"))
        .ReactComponent
  ),
  GCLoadingIndicatorForeground: loadable(
    async () =>
      (await import("../../assets/svg/gc-loading-indicator-foreground.svg"))
        .ReactComponent
  ),
  Envelope: loadable(
    async () => (await import("../../assets/svg/envelope.svg")).ReactComponent
  ),
  GCWordMarkLogoDark: loadable(
    async () =>
      (await import("../../assets/svg/gocardless-wordmark-rebrand-dark.svg"))
        .ReactComponent
  ),
  ImageSelectIcon: loadable(
    async () =>
      (await import("../../assets/svg/image-select.svg")).ReactComponent
  ),
  GroupIcon: loadable(
    async () => (await import("../../assets/svg/group.svg")).ReactComponent
  ),
  MoneyTimeIcon: loadable(
    async () => (await import("../../assets/svg/money-time.svg")).ReactComponent
  ),
  NotificationIcon: loadable(
    async () =>
      (await import("../../assets/svg/notification.svg")).ReactComponent
  ),
  RightChevronFilled: loadable(
    async () =>
      (await import("../../assets/svg/right-chevron-filled.svg")).ReactComponent
  ),
  SpinnerTick: loadable(
    async () =>
      (await import("../../assets/svg/spinner-tick.svg")).ReactComponent
  ),
  Sepa: loadable(
    async () => (await import("../../assets/svg/sepa.svg")).ReactComponent
  ),
  BankIcon: loadable(
    async () => (await import("../../assets/svg/bank-icon.svg")).ReactComponent
  ),
  Star: loadable(
    async () => (await import("../../assets/svg/star.svg")).ReactComponent
  ),
  ThankYouStep: loadable(
    async () =>
      (await import("../../assets/svg/thank-you-step.svg")).ReactComponent
  ),
  AccountVerifiedTick: loadable(
    async () =>
      (await import("../../assets/svg/account-verified-tick.svg"))
        .ReactComponent
  ),
  AccountVerifyingTick: loadable(
    async () =>
      (await import("../../assets/svg/account-verifying-tick.svg"))
        .ReactComponent
  ),
  AccountCouldNotBeVerified: loadable(
    async () => (await import("../../assets/svg/cross.svg")).ReactComponent
  ),
  VerificationInReview: loadable(
    async () =>
      (await import("../../assets/svg/verification-in-review.svg"))
        .ReactComponent
  ),
  ContrastSpinner: loadable(
    async () =>
      (await import("../../assets/svg/spinner-contrast.svg")).ReactComponent
  ),
  Present: loadable(
    async () => (await import("../../assets/svg/present.svg")).ReactComponent
  ),
};

export default registry;
