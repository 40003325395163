import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";

export enum DocumentRequirement {
  /* Charity confirmation */
  CharityName = "charity_name",

  /* Company identity */
  LegalEntityName = "legal_entity_name",
  RegistrationNumber = "registration_number",
  RegisteredAddress = "registered_address",
  EinNumber = "ein_number",
  LegalRepresentatives = "legal_representatives",

  /* High risk */
  CurrentBeneficialOwners = "current_beneficial_owners",
  CurrentUltimateBeneficialOwners = "current_ultimate_beneficial_owners",

  /* Partnership identity */
  OfficialPartnershipName = "official_partneship_name",
  NamesOfAllPartners = "names_of_all_partners",
  EntityType = "entity_type",
  PartnerNameAndDetails = "partner_name_and_details",
  NumberOfPartners = "number_of_partners",
  OfficialLegalName = "official_legal_name",

  /* Trust */
  TrustName = "trust_name",
  NamesOfTrustees = "names_of_trustees",
  NamesOfUltimateBeneficialOwners = "names_of_ultimate_beneficial_owners",
}

export const DocumentRequirementsNameMap = (
  i18n: I18n
): Record<DocumentRequirement, string> => ({
  /* Charity confirmation */
  [DocumentRequirement.CharityName]: i18n._(t`Charity name`),

  /* Company identity */
  [DocumentRequirement.LegalEntityName]: i18n._(t`Legal entity name`),
  [DocumentRequirement.RegistrationNumber]: i18n._(t`Registration number`),
  [DocumentRequirement.RegisteredAddress]: i18n._(t`Registered address`),
  [DocumentRequirement.EinNumber]: i18n._(
    t`Taxpayer identification number (EIN)`
  ),
  [DocumentRequirement.LegalRepresentatives]: i18n._(
    t`Names of legal representatives, directors or equivalent`
  ),

  /* High risk */
  [DocumentRequirement.CurrentBeneficialOwners]: i18n._(
    t`Current beneficial owners`
  ),
  [DocumentRequirement.CurrentUltimateBeneficialOwners]: i18n._(
    t`Current ultimate beneficial owners`
  ),

  /* Partnership identity */
  [DocumentRequirement.OfficialPartnershipName]: i18n._(
    t`Official partnership name`
  ),
  [DocumentRequirement.NamesOfAllPartners]: i18n._(t`Names of all partners`),
  [DocumentRequirement.EntityType]: i18n._(t`Entity type`),
  [DocumentRequirement.PartnerNameAndDetails]: i18n._(
    t`Partner's name and details`
  ),
  [DocumentRequirement.NumberOfPartners]: i18n._(
    t`Number of partners in the partnership`
  ),
  [DocumentRequirement.OfficialLegalName]: i18n._(t`Official legal name`),

  /* Trust */
  [DocumentRequirement.TrustName]: i18n._(t`Name of the Trust`),
  [DocumentRequirement.NamesOfTrustees]: i18n._(t`Name of trustee(s)`),
  [DocumentRequirement.NamesOfUltimateBeneficialOwners]: i18n._(
    t`Names of all ultimate beneficial owners`
  ),
});

export interface UploadDocOnlyFormValues {
  upload_docs: File[];
}

export interface BankAccountFormValues extends UploadDocOnlyFormValues {
  confirm_bank_account: boolean;
}

export interface IdentityFormValues extends UploadDocOnlyFormValues {
  identity_confirmation: boolean;
}
