import {
  ColorPreset,
  DD,
  DL,
  DLLayout,
  DT,
  FontWeight,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { useLingui } from "@lingui/react";
import { Fragment, useMemo } from "react";
import {
  DocumentRequirement,
  DocumentRequirementsNameMap,
} from "src/components/routes/Setup/optimised-verification-status/constants/DocumentRequirement";
import {
  CreditorDetails,
  getCompanyDetails,
} from "src/components/routes/Setup/optimised-verification-status/helpers/getCreditorDetails";

import { useSetupData } from "../../../common/hooks/useSetupData";

const DOCUMENT_REQUIREMENT_MAPPING: {
  [key in DocumentRequirement]?: keyof CreditorDetails;
} = {
  [DocumentRequirement.LegalEntityName]: "name",
  [DocumentRequirement.RegistrationNumber]: "entity_number",
  [DocumentRequirement.RegisteredAddress]: "address",
  [DocumentRequirement.EinNumber]: "entity_number",
};

export const CompanyDocumentsRequirements = ({
  requirements,
}: {
  requirements: DocumentRequirement[];
}) => {
  const { i18n } = useLingui();
  const DOCUMENT_REQUIREMENTS_NAME_MAP = DocumentRequirementsNameMap(i18n);

  const { creditorDetails } = useSetupData();

  const requirementsWithValues = useMemo(() => {
    if (!creditorDetails?.detail) {
      return [];
    }

    const companyDetails = getCompanyDetails(creditorDetails.detail);

    return requirements.map((requirement) => {
      const mappingKey = DOCUMENT_REQUIREMENT_MAPPING[requirement];

      return {
        name: requirement,
        value: mappingKey && companyDetails[mappingKey],
      };
    });
  }, [creditorDetails, requirements]);

  return (
    <DL layout={DLLayout.RowFixedTerm}>
      {requirementsWithValues.map(({ name, value }) => (
        <Fragment key={name}>
          <DT style={value ? {} : { gridColumn: "span 3" }}>
            <Text
              weight={FontWeight.Normal}
              preset={TypePreset.Body_01}
              color={ColorPreset.TextOnLight_01}
            >
              {DOCUMENT_REQUIREMENTS_NAME_MAP[name]}
            </Text>
          </DT>
          {value && (
            <DD>
              <Text
                weight={FontWeight.SemiBold}
                preset={TypePreset.Body_01}
                color={ColorPreset.TextOnLight_01}
                className="fs-mask"
              >
                {value}
              </Text>
            </DD>
          )}
        </Fragment>
      ))}
    </DL>
  );
};
