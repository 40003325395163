import { RequiredActionsStatus } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { useMemo } from "react";
import { Text } from "@gocardless/flux-react";
import { PennyTestDialog } from "src/components/routes/Setup/optimised-verification-status/common/PennyTestDialog";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";

import { RequiredDocument } from "../../required-documents/useRequiredDocuments";
import { bankVerificationWaitingTime } from "../../helpers/bankVerificationWaitingTime";

import { StatusItem } from "./StatusItem";

export const VerificationDepositStatus: React.FC<{
  requiredDocument: RequiredDocument;
}> = ({ requiredDocument }) => {
  const { isVariationOn: isPennyTestImprovementsEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.PENNY_TEST_IMPROVEMENTS,
    });

  const { title, additionalDetail, status } = useMemo(() => {
    const waitingTime = bankVerificationWaitingTime(requiredDocument);
    const { details } = requiredDocument;
    const bankAccountNumberEnding = (
      <Text className="fs-mask">{details?.account_number_ending}</Text>
    );

    switch (requiredDocument.status) {
      case RequiredActionsStatus.Successful:
        return {
          title: (
            <Trans>
              We&apos;ve verified the bank account ending{" "}
              {bankAccountNumberEnding}
            </Trans>
          ),
          status: RequiredActionsStatus.Successful,
        };
      default:
        return {
          title: (
            <Trans>
              We&apos;re verifying the bank account ending{" "}
              {bankAccountNumberEnding}
            </Trans>
          ),
          additionalDetail: isPennyTestImprovementsEnabled ? (
            <>
              <Trans>
                This usually takes {waitingTime} to complete after you send a
                payment to GoCardless.
              </Trans>
              <span> </span>
              <PennyTestDialog
                details={details}
                sourceComponent={VerificationDepositStatus.name}
              />
            </>
          ) : (
            <Trans>This usually takes {waitingTime} to complete.</Trans>
          ),
          status: RequiredActionsStatus.InReview,
        };
    }
  }, [requiredDocument, isPennyTestImprovementsEnabled]);

  return (
    <StatusItem
      title={title}
      additionalDetail={additionalDetail}
      status={status}
      lastTransitionTime={requiredDocument.lastTransitionTime}
    />
  );
};
