import { RequiredActionsStatus } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { Text } from "@gocardless/flux-react";

import { RequiredActionCardProps } from "../RequiredActionCard";
import { RequiredAction } from "../RequiredAction";

import { PartnershipDocumentUpload } from "./PartnershipDocumentUpload";

export const PartnershipIdentityCard = (props: RequiredActionCardProps) => {
  const { entity, actionStatus, notificationEmail: nEmail } = props;
  const entityTitle = <Text className="fs-mask">{entity.name || ""}</Text>;
  const notificationEmail = <Text className="fs-mask">{nEmail}</Text>;

  switch (actionStatus) {
    case RequiredActionsStatus.Pending:
      return (
        <RequiredAction
          header={<Trans>Verify details for {entityTitle}</Trans>}
          description={
            <Trans>Please upload a document which verifies your partners</Trans>
          }
          body={<PartnershipDocumentUpload {...props} />}
        />
      );
    case RequiredActionsStatus.PendingResponse:
      return (
        <RequiredAction
          header={<Trans>Verify details for {entityTitle}</Trans>}
          description={
            <Trans>
              We&apos;ve received your document and our verification team has
              reached out to you at <b>{notificationEmail}</b> for further
              information.
            </Trans>
          }
        />
      );
    default:
      return (
        <RequiredAction
          header={<Trans>We&apos;re verifying details for {entityTitle}</Trans>}
        />
      );
  }
};
