import { Box, Button, ButtonVariant, Form } from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { UploadedDocumentType } from "@gocardless/api/dashboard/types";
import { HavingTroubleDialog } from "src/components/routes/Setup/optimised-verification-status/common/HavingTroubleDialog";
import { AcceptedDocuments } from "src/components/routes/Setup/optimised-verification-status/common/AcceptedDocuments";
import { useUploadDocuments } from "src/components/routes/Setup/optimised-verification-status/required-documents/useUploadDocuments";
import { DocumentRequirements } from "src/components/routes/Setup/optimised-verification-status/common/DocumentRequirements";
import { RequiredActionCardProps } from "src/components/routes/Setup/optimised-verification-status/required-actions/RequiredActionCard";
import { useForm } from "react-hook-form";

import FormError from "../../common/FormError";
import { UploadDocOnlyFormValues } from "../../constants/DocumentRequirement";
import { useUploadDocValidation } from "../../hooks/useUploadDocValidations";
import { ControlledFileUpload } from "../../common/ControlledFileUpload";

export const CompanyUBOsUpload: React.FC<RequiredActionCardProps> = (props) => {
  const {
    creditorId,
    verificationId,
    acceptedDocuments,
    documentRequirements,
  } = props;

  const { uploading, uploadDocuments } = useUploadDocuments(
    creditorId,
    verificationId
  );

  const { validateFilesMaxOnly } = useUploadDocValidation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UploadDocOnlyFormValues>({
    defaultValues: {
      upload_docs: [],
    },
  });

  const uploadSelectedDocuments = async (value: UploadDocOnlyFormValues) => {
    uploadDocuments(value.upload_docs, UploadedDocumentType.RecentAnnualReport);
  };

  return (
    <Form onSubmit={handleSubmit(uploadSelectedDocuments)}>
      {documentRequirements && (
        <Box spaceBelow={1}>
          <DocumentRequirements requirements={documentRequirements} />
        </Box>
      )}

      <AcceptedDocuments multiple documents={acceptedDocuments} />

      <Box spaceAbove={2}>
        <ControlledFileUpload
          required
          name="upload_docs"
          control={control}
          uploading={uploading}
          validationRules={validateFilesMaxOnly}
        />
      </Box>
      {errors.upload_docs?.message ? (
        <FormError errorMessage={errors.upload_docs?.message} />
      ) : null}
      <Box spaceAbove={2}>
        <Button
          type="submit"
          variant={ButtonVariant.PrimaryOnLight}
          disabled={uploading}
        >
          <Trans>Upload</Trans>
        </Button>
      </Box>
      <HavingTroubleDialog sourceComponent={CompanyUBOsUpload.name} />
    </Form>
  );
};
