import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";

export const verificationDetails = () => [
  [i18n._(t({ id: "setup.amount", message: "Amount" })), "amount"],
  [i18n._(t({ id: "setup.reference", message: "Reference" })), "reference"],
  [i18n._(t({ id: "setup.payee-name", message: "Payee name" })), "payee_name"],
  [
    i18n._(t({ id: "setup.account-number", message: "Account number" })),
    "account_number",
  ],
  [i18n._(t({ id: "setup.sort-code", message: "Sort code" })), "branch_code"],
  [i18n._(t({ id: "iban", message: "IBAN" })), "iban"],
  [i18n._(t({ id: "setup.bic", message: "BIC" })), "bic"],
  [i18n._(t({ id: "setup.bsb-code", message: "BSB code" })), "bsb_code"],
];
