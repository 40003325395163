import { CountryCodes } from "src/common/country";

import { BankAccountIdentityStatus } from "../status-cards/status-item/BankAccountIdentityStatus";
import { BankAccountIdentityCard } from "../required-actions/bank-account-identity/BankAccountIdentityCard";
import { AcceptedDocuments } from "../../common/constants/AcceptedDocuments";

const BANK_ACCOUNT_ACCEPTED_DOCUMENTS_DEFAULT = [
  AcceptedDocuments.BankAccountStatement,
  AcceptedDocuments.BankConfirmationLetter,
  AcceptedDocuments.BankBankingScreenshot,
];

export const BANK_ACCOUNT_ACCEPTED_DOCUMENTS_COUNTRY_MAP: Record<
  string,
  AcceptedDocuments[]
> = {
  [CountryCodes.US]: [
    AcceptedDocuments.BankAccountStatement,
    AcceptedDocuments.AccountOpeningStatement,
    AcceptedDocuments.BankConfirmationLetter,
    AcceptedDocuments.BankBankingScreenshot,
  ],
  [CountryCodes.GB]: [
    AcceptedDocuments.BankAccountStatement,
    AcceptedDocuments.AccountOpeningStatement,
    AcceptedDocuments.BankConfirmationLetter,
    AcceptedDocuments.BankBankingScreenshot,
  ],
  [CountryCodes.FR]: [AcceptedDocuments.BankAccountStatement],
};

export const BankAccountIdentityConfig = {
  acceptedDocuments: (geo: CountryCodes) =>
    BANK_ACCOUNT_ACCEPTED_DOCUMENTS_COUNTRY_MAP[geo] ||
    BANK_ACCOUNT_ACCEPTED_DOCUMENTS_DEFAULT,

  statusComponent: BankAccountIdentityStatus,

  actionComponent: BankAccountIdentityCard,
};
