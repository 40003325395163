import {
  CreditorType,
  RequiredActionsStatus,
} from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { useMemo } from "react";

import { RequiredDocument } from "../../required-documents/useRequiredDocuments";

import { StatusItem } from "./StatusItem";

export const HighRiskVerificationStatus: React.FC<{
  requiredDocument: RequiredDocument;
  creditorType: CreditorType;
}> = ({ requiredDocument, creditorType }) => {
  const { title } = useMemo(() => {
    switch (requiredDocument.status) {
      case RequiredActionsStatus.Successful:
        return {
          title:
            creditorType === CreditorType.Individual ? (
              <Trans>We&apos;ve verified financial position</Trans>
            ) : creditorType === CreditorType.Charity ? (
              <Trans>
                We&apos;ve verified beneficial owners & financial position
              </Trans>
            ) : (
              <Trans>
                We&apos;ve verified business owners & financial position
              </Trans>
            ),
        };
      case RequiredActionsStatus.Pending:
      case RequiredActionsStatus.PendingResponse:
        return {
          title:
            creditorType === CreditorType.Individual ? (
              <Trans>Confirm financial position</Trans>
            ) : creditorType === CreditorType.Charity ? (
              <Trans>Confirm beneficial owners & financial position</Trans>
            ) : (
              <Trans>Confirm business owners & financial position</Trans>
            ),
        };
      default:
        return {
          title:
            creditorType === CreditorType.Individual ? (
              <Trans>We&apos;re verifying financial position</Trans>
            ) : creditorType === CreditorType.Charity ? (
              <Trans>
                We&apos;re verifying beneficial owners & financial position
              </Trans>
            ) : (
              <Trans>
                We&apos;re verifying business owners & financial position
              </Trans>
            ),
        };
    }
  }, [requiredDocument, creditorType]);

  return (
    <StatusItem
      requiredActionId={requiredDocument.verificationId}
      title={title}
      status={requiredDocument.status}
      lastTransitionTime={requiredDocument.lastTransitionTime}
    />
  );
};
