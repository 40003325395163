import { RequiredActionsStatus } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { useMemo } from "react";
import { Text } from "@gocardless/flux-react";

import { RequiredDocument } from "../../required-documents/useRequiredDocuments";

import { StatusItem } from "./StatusItem";

export const BankAccountIdentityStatus: React.FC<{
  requiredDocument: RequiredDocument;
}> = ({ requiredDocument }) => {
  const { bankAccountNumberEnding: banEnding, status } = requiredDocument;
  const { title } = useMemo(() => {
    const bankAccountNumberEnding = (
      <Text className="fs-mask">{banEnding}</Text>
    );
    switch (status) {
      case RequiredActionsStatus.Successful:
        return {
          title: (
            <Trans>
              We&apos;ve verified the bank account ending{" "}
              {bankAccountNumberEnding}
            </Trans>
          ),
        };
      case RequiredActionsStatus.Pending:
      case RequiredActionsStatus.PendingResponse:
        return {
          title: (
            <Trans>
              Verify the bank account ending {bankAccountNumberEnding}
            </Trans>
          ),
        };
      default:
        return {
          title: (
            <Trans>
              We&apos;re verifying the bank account ending{" "}
              {bankAccountNumberEnding}
            </Trans>
          ),
        };
    }
  }, [status, banEnding]);

  return (
    <StatusItem
      requiredActionId={requiredDocument.verificationId}
      title={title}
      status={requiredDocument.status}
      lastTransitionTime={requiredDocument.lastTransitionTime}
    />
  );
};
