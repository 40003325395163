import { Box, FormFieldStatus, Hint, P } from "@gocardless/flux-react";

interface FormErrorProps {
  errorMessage: string;
}
function FormError({ errorMessage }: FormErrorProps) {
  return (
    <Box spaceBelow={1}>
      <Hint status={FormFieldStatus.Danger}>
        <Box spaceAbove={0.25}>
          <P>{errorMessage}</P>
        </Box>
      </Hint>
    </Box>
  );
}

export default FormError;
