import { Box, H3, P, TypePreset } from "@gocardless/flux-react";

export const RequiredAction: React.FC<{
  header: JSX.Element;
  description?: JSX.Element;
  body?: JSX.Element;
}> = ({ header, description, body }) => (
  <Box flexBasis="100%">
    <H3 preset={TypePreset.Heading_03}>{header}</H3>
    {description && (
      <P preset={TypePreset.Body_01} spaceBelow={1} spaceAbove={1}>
        {description}
      </P>
    )}
    {body}
  </Box>
);
