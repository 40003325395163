import {
  Checkbox,
  ColorPreset,
  FontWeight,
  P,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { Controller, FieldValues } from "react-hook-form";

interface ControlledCheckBoxProps extends FieldValues {
  name: string;
  required: boolean | string;
  labelText: string;
  description: string;
  resetHasSubmitted: () => void;
}

export const ControlledCheckBox = ({
  name,
  control,
  required,
  labelText,
  description,
  resetHasSubmitted,
}: ControlledCheckBoxProps) => (
  <Controller
    name={name}
    control={control}
    rules={{
      required: required,
    }}
    render={({ field }) => (
      <Checkbox onChange={field.onChange} onBlur={resetHasSubmitted}>
        <Text preset={TypePreset.Heading_01}>{labelText}</Text>
        <P
          preset={TypePreset.Caption_01}
          weight={FontWeight.Normal}
          color={ColorPreset.TextOnLight_02}
        >
          {description}
        </P>
      </Checkbox>
    )}
  />
);
