import { CountryCodes } from "src/common/country";
import { captureException } from "@sentry/browser";

import { DocumentRequirement } from "../constants/DocumentRequirement";
import { CompanyIdentityCard } from "../required-actions/company-identity/CompanyIdentityCard";
import { RequiredDocumentStatus } from "../status-cards/status-item/RequiredDocumentStatus";
import { AcceptedDocuments } from "../../common/constants/AcceptedDocuments";

export const COMPANY_ACCEPTED_DOCUMENTS_DEFAULT = [
  AcceptedDocuments.RecentFilingFromStateRegistry,
  AcceptedDocuments.GovernmentIssuedBusinessLicense,
  AcceptedDocuments.RecentAnnualReport,
  AcceptedDocuments.IncorporationDocuments,
];

const ACCEPTED_DOCUMENTS_COUNTRY_MAP: Record<string, AcceptedDocuments[]> = {
  [CountryCodes.AU]: [AcceptedDocuments.CompanyExtractAsic],
  [CountryCodes.CA]: [AcceptedDocuments.CertificateOfIncorporation],
  [CountryCodes.CH]: [AcceptedDocuments.CompanyZefixCantonRegistryEntry],
  [CountryCodes.GB]: [AcceptedDocuments.RecentConfirmationStatement],
  [CountryCodes.NZ]: [AcceptedDocuments.CompanyEntryNzbn],
  [CountryCodes.US]: [
    AcceptedDocuments.RecentFilingFromStateRegistry,
    AcceptedDocuments.IncorporationDocuments,
    AcceptedDocuments.RecentAnnualReport,
    AcceptedDocuments.CertificateOfGoodStanding,
  ],
  [CountryCodes.ZA]: [
    AcceptedDocuments.CertificateOfConfirmationFromCipcRegister,
  ],

  /* GC SAS */
  [CountryCodes.AT]: [AcceptedDocuments.CompanyEntryAtRegistry],
  [CountryCodes.BE]: [AcceptedDocuments.CompanyConstitutionCbe],
  [CountryCodes.BG]: [AcceptedDocuments.CompanyRegisterOfNpleEntry],
  [CountryCodes.CY]: [
    AcceptedDocuments.RegistarOfCompaniesOfficialReceiverEntry,
  ],
  [CountryCodes.CZ]: [AcceptedDocuments.CompanyEntryVerejnyRejstrik],
  [CountryCodes.DE]: [
    AcceptedDocuments.CompanyRegistrationUnternehmensregister,
  ],
  [CountryCodes.DK]: [AcceptedDocuments.CompanyEntryCentralBusinessRegisterDk],
  [CountryCodes.EE]: [AcceptedDocuments.CompanyAnnualReturn],
  [CountryCodes.ES]: [
    AcceptedDocuments.CompanyDeedOfIncorporationMercantilRegister,
  ],
  [CountryCodes.FI]: [AcceptedDocuments.CompanyRegistrationTradeRegister],
  [CountryCodes.FR]: [AcceptedDocuments.CompanyKbisExtract],
  [CountryCodes.HR]: [AcceptedDocuments.CompanySuskiRegistarEntry],
  [CountryCodes.HU]: [AcceptedDocuments.CertifiedListOfDirectors],
  [CountryCodes.IE]: [AcceptedDocuments.CompanyAnnualReturnCro],
  [CountryCodes.IT]: [AcceptedDocuments.CompanyReportRegistroDelleImprese],
  [CountryCodes.LU]: [
    AcceptedDocuments.CompanyRegistrationEuropeanJusticePortal,
  ],
  [CountryCodes.MT]: [AcceptedDocuments.CompanyMfsaEntry],
  [CountryCodes.NL]: [AcceptedDocuments.CompanyRegistrationKvk],
  [CountryCodes.NO]: [
    AcceptedDocuments.CompanyRegistrationBronnoysundregistrene,
  ],
  [CountryCodes.PL]: [AcceptedDocuments.KrsRegistrationEntry],
  [CountryCodes.PT]: [AcceptedDocuments.CertifiedListOfDirectors],
  [CountryCodes.RO]: [AcceptedDocuments.CompanyNtroRegistration],
  [CountryCodes.SE]: [AcceptedDocuments.CompanyArsredovisning],
  [CountryCodes.SI]: [AcceptedDocuments.CompanyEntryAjpesRegister],
  [CountryCodes.SK]: [AcceptedDocuments.CompanyObchodnyRegisterEntry],
};

export const COMPANY_DOCUMENT_REQUIREMENTS_DEFAULT = [
  DocumentRequirement.LegalEntityName,
  DocumentRequirement.RegistrationNumber,
  DocumentRequirement.RegisteredAddress,
  DocumentRequirement.LegalRepresentatives,
];

const DOCUMENT_REQUIREMENTS_COUNTRY_MAP: Record<string, DocumentRequirement[]> =
  {
    [CountryCodes.AU]: [
      DocumentRequirement.LegalEntityName,
      DocumentRequirement.RegistrationNumber,
      DocumentRequirement.RegisteredAddress,
    ],
    [CountryCodes.GB]: [
      DocumentRequirement.LegalEntityName,
      DocumentRequirement.RegistrationNumber,
      DocumentRequirement.RegisteredAddress,
    ],
    [CountryCodes.CA]: [
      DocumentRequirement.LegalEntityName,
      DocumentRequirement.RegistrationNumber,
      DocumentRequirement.RegisteredAddress,
    ],
    [CountryCodes.CH]: [
      DocumentRequirement.LegalEntityName,
      DocumentRequirement.RegistrationNumber,
      DocumentRequirement.RegisteredAddress,
    ],
    [CountryCodes.NZ]: [
      DocumentRequirement.LegalEntityName,
      DocumentRequirement.RegistrationNumber,
      DocumentRequirement.RegisteredAddress,
    ],
    [CountryCodes.US]: [
      DocumentRequirement.LegalEntityName,
      DocumentRequirement.EinNumber,
      DocumentRequirement.RegisteredAddress,
    ],
    [CountryCodes.ZA]: [
      DocumentRequirement.LegalEntityName,
      DocumentRequirement.RegistrationNumber,
      DocumentRequirement.RegisteredAddress,
    ],
  };

export const CompanyIdentityConfig = {
  acceptedDocuments: (geo: CountryCodes) => {
    const documents = ACCEPTED_DOCUMENTS_COUNTRY_MAP[geo];
    if (!documents) {
      const errorMessage = `No accepted documents found for country code: ${geo}`;
      captureException({ error: new Error(errorMessage) });
      return COMPANY_ACCEPTED_DOCUMENTS_DEFAULT;
    }
    return documents;
  },

  documentRequirements: (geo: CountryCodes) =>
    DOCUMENT_REQUIREMENTS_COUNTRY_MAP[geo] ||
    COMPANY_DOCUMENT_REQUIREMENTS_DEFAULT,

  statusComponent: RequiredDocumentStatus,

  actionComponent: CompanyIdentityCard,
};
