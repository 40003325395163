import {
  Button,
  ButtonVariant,
  ButtonLayout,
  Glyph,
  Icon,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { SdkInitMethod } from "onfido-sdk-ui";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { getOnfidoToken } from "./useOnfido";
import { VerifyWithOnfidoPopup } from "./VerifyWithOnfidoPopup";

interface TriggerOnfidoFlowProps {
  creditorId: string;
  verificationId: string;
  showOnfidoModal: boolean;
  updateRequiredDocuments: () => Promise<void>;
  setShowOnfidoModal: Dispatch<SetStateAction<boolean>>;
  setShouldRenderOnfidoUpload: Dispatch<SetStateAction<boolean>>;
}

export const TriggerOnfidoFlow = ({
  creditorId,
  verificationId,
  showOnfidoModal,

  updateRequiredDocuments,
  setShowOnfidoModal,
  setShouldRenderOnfidoUpload,
}: TriggerOnfidoFlowProps) => {
  const [onfido, setOnfido] = useState<{ init: SdkInitMethod }>();
  const [onfidoToken, setOnfidoToken] = useState<string | undefined>();

  useEffect(() => {
    import("onfido-sdk-ui").then((importedOnfido) => {
      setOnfido(importedOnfido);
    });
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getOnfidoToken(verificationId);

      if (token) {
        setOnfidoToken(token);
      } else {
        setShouldRenderOnfidoUpload(false);
      }
    };

    fetchToken();
  }, [setShouldRenderOnfidoUpload, verificationId]);

  return onfidoToken && onfido ? (
    <>
      <Button
        type="submit"
        variant={ButtonVariant.PrimaryOnLight}
        layout={[ButtonLayout.Full, ButtonLayout.Inline]}
      >
        <Trans>Start</Trans>
      </Button>

      {showOnfidoModal && (
        <VerifyWithOnfidoPopup
          onfidoToken={onfidoToken}
          creditorId={creditorId}
          verificationId={verificationId}
          onfido={onfido}
          updateRequiredDocuments={updateRequiredDocuments}
          onClose={() => setShowOnfidoModal(false)}
          onOnfidoError={() => setShouldRenderOnfidoUpload(false)}
        />
      )}
    </>
  ) : (
    <Button
      data-testid="loading"
      variant={ButtonVariant.PrimaryOnLight}
      layout={[ButtonLayout.Full, ButtonLayout.Inline]}
      disabled
    >
      <Icon name={Glyph.Spinner} size="20px" />
    </Button>
  );
};
