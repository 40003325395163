import { CountryCodes } from "src/common/country";

export const OnfidoSupportedCountryCodes = [
  CountryCodes.AD,
  CountryCodes.AE,
  CountryCodes.AF,
  CountryCodes.AG,
  CountryCodes.AL,
  CountryCodes.AM,
  CountryCodes.AR,
  CountryCodes.AT,
  CountryCodes.AU,
  CountryCodes.AW,
  CountryCodes.AX,
  CountryCodes.AZ,
  CountryCodes.BA,
  CountryCodes.BB,
  CountryCodes.BE,
  CountryCodes.BF,
  CountryCodes.BG,
  CountryCodes.BH,
  CountryCodes.BI,
  CountryCodes.BJ,
  CountryCodes.BM,
  CountryCodes.BN,
  CountryCodes.BR,
  CountryCodes.BT,
  CountryCodes.BY,
  CountryCodes.BZ,
  CountryCodes.CA,
  CountryCodes.CF,
  CountryCodes.CG,
  CountryCodes.CH,
  CountryCodes.CI,
  CountryCodes.CK,
  CountryCodes.CL,
  CountryCodes.CM,
  CountryCodes.CN,
  CountryCodes.CO,
  CountryCodes.CR,
  CountryCodes.CU,
  CountryCodes.CY,
  CountryCodes.CZ,
  CountryCodes.DE,
  CountryCodes.DJ,
  CountryCodes.DK,
  CountryCodes.DM,
  CountryCodes.DO,
  CountryCodes.DZ,
  CountryCodes.EC,
  CountryCodes.EE,
  CountryCodes.EG,
  CountryCodes.ES,
  CountryCodes.ET,
  CountryCodes.FI,
  CountryCodes.FJ,
  CountryCodes.FO,
  CountryCodes.FR,
  CountryCodes.GA,
  CountryCodes.GB,
  CountryCodes.GD,
  CountryCodes.GE,
  CountryCodes.GG,
  CountryCodes.GI,
  CountryCodes.GN,
  CountryCodes.GQ,
  CountryCodes.GR,
  CountryCodes.GT,
  CountryCodes.HK,
  CountryCodes.HN,
  CountryCodes.HR,
  CountryCodes.HU,
  CountryCodes.ID,
  CountryCodes.IE,
  CountryCodes.IL,
  CountryCodes.IM,
  CountryCodes.IN,
  CountryCodes.IR,
  CountryCodes.IS,
  CountryCodes.IT,
  CountryCodes.JE,
  CountryCodes.JM,
  CountryCodes.JP,
  CountryCodes.KE,
  CountryCodes.KG,
  CountryCodes.KH,
  CountryCodes.KN,
  CountryCodes.KR,
  CountryCodes.KW,
  CountryCodes.KY,
  CountryCodes.KZ,
  CountryCodes.LB,
  CountryCodes.LC,
  CountryCodes.LI,
  CountryCodes.LK,
  CountryCodes.LS,
  CountryCodes.LT,
  CountryCodes.LU,
  CountryCodes.LV,
  CountryCodes.MA,
  CountryCodes.MC,
  CountryCodes.MD,
  CountryCodes.ME,
  CountryCodes.ML,
  CountryCodes.MR,
  CountryCodes.MS,
  CountryCodes.MT,
  CountryCodes.MW,
  CountryCodes.MX,
  CountryCodes.MY,
  CountryCodes.NA,
  CountryCodes.NE,
  CountryCodes.NF,
  CountryCodes.NG,
  CountryCodes.NI,
  CountryCodes.NL,
  CountryCodes.NO,
  CountryCodes.NP,
  CountryCodes.NZ,
  CountryCodes.OM,
  CountryCodes.PA,
  CountryCodes.PE,
  CountryCodes.PH,
  CountryCodes.PK,
  CountryCodes.PL,
  CountryCodes.PR,
  CountryCodes.PS,
  CountryCodes.PT,
  CountryCodes.PY,
  CountryCodes.QA,
  CountryCodes.RO,
  CountryCodes.RS,
  CountryCodes.RU,
  CountryCodes.SA,
  CountryCodes.SD,
  CountryCodes.SE,
  CountryCodes.SG,
  CountryCodes.SI,
  CountryCodes.SK,
  CountryCodes.SO,
  CountryCodes.SV,
  CountryCodes.SY,
  CountryCodes.TC,
  CountryCodes.TG,
  CountryCodes.TH,
  CountryCodes.TJ,
  CountryCodes.TM,
  CountryCodes.TN,
  CountryCodes.TR,
  CountryCodes.TT,
  CountryCodes.TV,
  CountryCodes.TW,
  CountryCodes.TZ,
  CountryCodes.UA,
  CountryCodes.UG,
  CountryCodes.US,
  CountryCodes.UZ,
  CountryCodes.VC,
  CountryCodes.VE,
  CountryCodes.VG,
  CountryCodes.VN,
  CountryCodes.VU,
  CountryCodes.WS,
  CountryCodes.ZA,
  CountryCodes.ZM,
  CountryCodes.ZW,
];
