import { RequiredActionsStatus } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { Text } from "@gocardless/flux-react";

import { RequiredAction } from "../RequiredAction";
import { RequiredActionCardProps } from "../RequiredActionCard";

import { BankDocumentUpload } from "./BankDocumentUpload";

export const BankAccountIdentityCard = (props: RequiredActionCardProps) => {
  const {
    actionStatus,
    bankAccountNumberEnding: banEnding,
    notificationEmail: nEmail,
  } = props;
  const bankAccountNumberEnding = <Text className="fs-mask">{banEnding}</Text>;
  const notificationEmail = <Text className="fs-mask">{nEmail}</Text>;

  switch (actionStatus) {
    case RequiredActionsStatus.Pending:
      return (
        <RequiredAction
          header={
            <Trans>
              Verify the bank account ending {bankAccountNumberEnding}
            </Trans>
          }
          description={
            <Trans>
              We need you to provide proof of bank account ownership.
            </Trans>
          }
          body={<BankDocumentUpload {...props} />}
        />
      );
    case RequiredActionsStatus.PendingResponse:
      return (
        <RequiredAction
          header={
            <Trans>
              Verify the bank account ending {bankAccountNumberEnding}
            </Trans>
          }
          description={
            <Trans>
              We&apos;ve received your document and our verification team has
              reached out to you at <b>{notificationEmail}</b> for further
              information.
            </Trans>
          }
        />
      );
    default:
      return (
        <RequiredAction
          header={
            <Trans>
              We&apos;re verifying the bank account ending{" "}
              {bankAccountNumberEnding}
            </Trans>
          }
        />
      );
  }
};
