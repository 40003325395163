import { RequiredActionsStatus } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { useMemo } from "react";
import { getPersonName } from "src/components/routes/Setup/optimised-verification-status/helpers/getPersonName";
import { Text } from "@gocardless/flux-react";

import { RequiredDocument } from "../../required-documents/useRequiredDocuments";
import usePersonalIdentityAdditionalDetail from "../../hooks/usePersonalIdentityAdditionalDetail";

import { StatusItem } from "./StatusItem";

export const PersonIdentityStatus: React.FC<{
  requiredDocument: RequiredDocument;
}> = ({ requiredDocument }) => {
  const lastStateChange = useMemo(
    () =>
      requiredDocument?.lastTransitionTime
        ? new Date(requiredDocument.lastTransitionTime)
        : new Date(),
    [requiredDocument?.lastTransitionTime]
  );

  const detail = usePersonalIdentityAdditionalDetail(lastStateChange);

  const { title, additionalDetail } = useMemo(() => {
    const { entity } = requiredDocument;
    const entityTitle = (
      <Text className="fs-mask">{getPersonName(entity)}</Text>
    );

    switch (requiredDocument.status) {
      case RequiredActionsStatus.Successful:
        return {
          title: (
            <Trans>
              We&apos;ve verified identity details for {entityTitle}
            </Trans>
          ),
        };
      case RequiredActionsStatus.Pending:
      case RequiredActionsStatus.PendingResponse:
        return {
          title: <Trans>Verify identity details for {entityTitle}</Trans>,
        };
      default:
        return {
          title: (
            <Trans>
              We&apos;re verifying identity details for {entityTitle}
            </Trans>
          ),
          additionalDetail: detail,
        };
    }
  }, [detail, requiredDocument]);

  return (
    <StatusItem
      requiredActionId={requiredDocument.verificationId}
      title={title}
      additionalDetail={additionalDetail}
      status={requiredDocument.status}
      lastTransitionTime={requiredDocument.lastTransitionTime}
    />
  );
};
