import { RequiredActionsStatus } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { CountryCodes } from "src/common/country";
import { Text } from "@gocardless/flux-react";

import { RequiredActionCardProps } from "../RequiredActionCard";
import { RequiredAction } from "../RequiredAction";

import { PartnersConfirmationUpload } from "./PartnersConfirmationUpload";

export const PartnersConfirmationCard = (props: RequiredActionCardProps) => {
  const { actionStatus, creditorGeo, notificationEmail: nEmail } = props;
  const notificationEmail = <Text className="fs-mask">{nEmail}</Text>;

  switch (actionStatus) {
    case RequiredActionsStatus.Pending:
      return (
        <RequiredAction
          header={<Trans>Verify your partners details</Trans>}
          description={
            creditorGeo === CountryCodes.AU ? (
              <Trans>
                If one of more partners is a legal entity (Trust or a company),
                please upload one of the following documents for each legal
                entity partner:
              </Trans>
            ) : creditorGeo === CountryCodes.CA ? (
              <Trans>
                If one or more partners is a company, please upload one of the
                following documents for each legal entity partner:
              </Trans>
            ) : undefined
          }
          body={<PartnersConfirmationUpload {...props} />}
        />
      );
    case RequiredActionsStatus.PendingResponse:
      return (
        <RequiredAction
          header={<Trans>Verify your partners details</Trans>}
          description={
            <Trans>
              We&apos;ve received your document and our verification team has
              reached out to you at <b>{notificationEmail}</b> for further
              information.
            </Trans>
          }
        />
      );
    default:
      return (
        <RequiredAction
          header={<Trans>We&apos;re verifying your partners details</Trans>}
        />
      );
  }
};
