export { default as Image } from "./Image";
export { default as LocalisedImage } from "./LocalisedImage";

import { ImageProps as _ImageProps, ImageName as _ImageName } from "./Image";
import {
  LocalisedImageProps as _LocalisedImageProps,
  LocalisedImageName as _LocalisedImageName,
} from "./LocalisedImage";

export type ImageProps = _ImageProps;
export type ImageName = _ImageName;

export type LocalisedImageProps = _LocalisedImageProps;
export type LocalisedImageName = _LocalisedImageName;
