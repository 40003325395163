import {
  Box,
  ColorPreset,
  H4,
  LI,
  TypePreset,
  UL,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import {
  AcceptedDocuments as AcceptedDocument,
  AcceptedDocumentsNameMap,
  AcceptedDocumentsWithMultipleSides,
} from "../../common/constants/AcceptedDocuments";

export const AcceptedDocuments = ({
  documents,
  multiple = false,
  bothSides = false,
}: {
  documents: AcceptedDocument[];
  multiple?: boolean;
  bothSides?: boolean;
}) => {
  const { i18n } = useLingui();
  const ACCEPTED_DOCUMENTS_NAME_MAP = AcceptedDocumentsNameMap(i18n);

  return (
    <Box>
      <H4
        spaceBelow={0.5}
        preset={TypePreset.Body_01}
        color={ColorPreset.TextOnLight_01}
      >
        {multiple ? (
          <Trans>What we can accept:</Trans>
        ) : (
          <Trans>We can accept one of the following:</Trans>
        )}
      </H4>
      <UL preset={TypePreset.Body_01} color={ColorPreset.TextOnLight_01}>
        {documents.map((item) =>
          bothSides && AcceptedDocumentsWithMultipleSides.includes(item) ? (
            <LI key={item}>
              <Trans>
                <b>Front and back</b> of a
              </Trans>{" "}
              {ACCEPTED_DOCUMENTS_NAME_MAP[item]}
            </LI>
          ) : (
            <LI key={item}>{ACCEPTED_DOCUMENTS_NAME_MAP[item]}</LI>
          )
        )}
      </UL>
    </Box>
  );
};
