import {
  Box,
  CSSRulesFunction,
  CSSValue,
  ResponsiveValue,
  SpaceScale,
  useTheme,
} from "@gocardless/flux-react";

import registry from "./registry";

export type ImageName = keyof typeof registry;

export interface ImageProps {
  name: ImageName;
  spacing?: ResponsiveValue<CSSValue<SpaceScale>>;
  width?: ResponsiveValue<number | string>;
  height?: ResponsiveValue<number | string>;
}

const svgStyle: CSSRulesFunction<ImageProps> = (
  theme,
  { width = "100%", height = "100%" }
) => [
  { display: "block" },
  theme.responsive(width, (value) => ({
    width: value,
  })),
  theme.responsive(height, (value) => ({
    height: value,
  })),
];
const boxStyle: CSSRulesFunction<ImageProps> = (theme, props) => [
  { position: "relative" },
  theme.responsive(props.spacing, (s) => ({ margin: theme.spacing(s) })),
  theme.responsive(props.width, (value) => ({
    width: value,
  })),
  theme.responsive(props.height, (value) => ({
    height: value,
  })),
];

const Image: React.FC<ImageProps> = (props) => {
  const { theme } = useTheme();
  const SVG = registry[props.name];

  return (
    <Box layout="inline-block" css={boxStyle(theme, props)} aria-hidden>
      <SVG css={svgStyle(theme, props)} />
    </Box>
  );
};

export default Image;
