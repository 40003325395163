import { RequiredActionsStatus } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { useMemo } from "react";

import { RequiredDocument } from "../../required-documents/useRequiredDocuments";

import { StatusItem } from "./StatusItem";

export const CompanyUBOsStatus: React.FC<{
  requiredDocument: RequiredDocument;
}> = ({ requiredDocument }) => {
  const { title } = useMemo(() => {
    switch (requiredDocument.status) {
      case RequiredActionsStatus.Successful:
        return {
          title: (
            <Trans>We&apos;ve verified your beneficial owner details</Trans>
          ),
        };
      case RequiredActionsStatus.Pending:
      case RequiredActionsStatus.PendingResponse:
        return {
          title: <Trans>Verify your beneficial owner details</Trans>,
        };
      default:
        return {
          title: (
            <Trans>We&apos;re verifying your beneficial owner details</Trans>
          ),
        };
    }
  }, [requiredDocument]);

  return (
    <StatusItem
      requiredActionId={requiredDocument.verificationId}
      title={title}
      status={requiredDocument.status}
      lastTransitionTime={requiredDocument.lastTransitionTime}
    />
  );
};
