import { RequiredActionsStatus } from "@gocardless/api/dashboard/types";

export const VERIFICATION_IN_REVIEW_STATES = [
  RequiredActionsStatus.InReview,
  RequiredActionsStatus.InAutomaticReview,
  RequiredActionsStatus.Processing,
];

export const VERIFICATION_PENDING_STATES = [
  RequiredActionsStatus.Pending,
  RequiredActionsStatus.PendingResponse,
];
