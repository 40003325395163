import { UploadedDocumentType } from "@gocardless/api/dashboard/types";
import {
  Box,
  Button,
  ButtonVariant,
  FontWeight,
  Form,
  H4,
  P,
  TypePreset,
  TypeScale,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useForm } from "react-hook-form";
import { CountryCodes, countryCodeToName } from "src/common/country";

import { getPersonName } from "../../helpers/getPersonName";
import { AcceptedDocuments } from "../../common/AcceptedDocuments";
import { HavingTroubleDialog } from "../../common/HavingTroubleDialog";
import { useUploadDocuments } from "../../required-documents/useUploadDocuments";
import { RequiredActionCardProps } from "../RequiredActionCard";
import FormError from "../../common/FormError";
import { UploadDocOnlyFormValues } from "../../constants/DocumentRequirement";
import { useUploadDocValidation } from "../../hooks/useUploadDocValidations";
import { ControlledFileUpload } from "../../common/ControlledFileUpload";

export const AddressDocumentUpload: React.FC<RequiredActionCardProps> = ({
  creditorId,
  verificationId,
  acceptedDocuments,
  entity,
}) => {
  const { uploading, uploadDocuments } = useUploadDocuments(
    creditorId,
    verificationId
  );

  const { i18n } = useLingui();

  const { validateFilesMaxOnly } = useUploadDocValidation();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<UploadDocOnlyFormValues>({
    defaultValues: {
      upload_docs: [],
    },
  });

  const uploadSelectedDocuments = async (value: UploadDocOnlyFormValues) => {
    await uploadDocuments(value.upload_docs, UploadedDocumentType.UtilityBill);
  };

  const verificationDuration = entity.country_code === CountryCodes.US ? 2 : 6;

  return (
    <Box>
      <Form onSubmit={handleSubmit(uploadSelectedDocuments)}>
        <Box spaceBelow={1} spaceAbove={1}>
          <H4 preset={TypePreset.Heading_01}>
            <Trans>The document provided must clearly show:</Trans>
          </H4>

          <P
            weight={FontWeight.Light}
            size={TypeScale.Size_02}
            className="fs-mask"
          >
            {getPersonName(entity)}
          </P>
          <P
            weight={FontWeight.Light}
            size={TypeScale.Size_02}
            className="fs-mask"
          >
            {entity.building_name}
          </P>
          <P
            weight={FontWeight.Light}
            size={TypeScale.Size_02}
            className="fs-mask"
          >
            {entity.flat_number}
          </P>
          <P
            weight={FontWeight.Light}
            size={TypeScale.Size_02}
            className="fs-mask"
          >
            {entity.street}
          </P>
          <P
            weight={FontWeight.Light}
            size={TypeScale.Size_02}
            className="fs-mask"
          >
            {entity.city} {entity.postal_code}
          </P>
          <P
            weight={FontWeight.Light}
            size={TypeScale.Size_02}
            spaceBelow={1}
            className="fs-mask"
          >
            {countryCodeToName(i18n)[entity.country_code as CountryCodes]}
          </P>
          <AcceptedDocuments documents={acceptedDocuments} />
        </Box>
        <Box spaceAbove={1}>
          <P preset={TypePreset.Body_01}>
            <Trans>
              Please ensure that the document uploaded displays date of issue
              within the last {verificationDuration} months.
            </Trans>
          </P>
        </Box>
        <Box spaceAbove={2}>
          <ControlledFileUpload
            required
            name="upload_docs"
            control={control}
            uploading={uploading}
            validationRules={validateFilesMaxOnly}
          />
        </Box>
        {errors.upload_docs?.message ? (
          <FormError errorMessage={errors.upload_docs.message} />
        ) : null}
        <Box spaceAbove={2}>
          <Button
            type="submit"
            variant={ButtonVariant.PrimaryOnLight}
            disabled={uploading}
          >
            <Trans>Upload</Trans>
          </Button>
        </Box>
      </Form>
      <HavingTroubleDialog sourceComponent={AddressDocumentUpload.name} />
    </Box>
  );
};
