import { CountryCodes } from "src/common/country";

import { AcceptedDocuments } from "../../common/constants/AcceptedDocuments";
import { PersonIdentityCard } from "../required-actions/person-identity/PersonIdentityCard";
import { PersonIdentityStatus } from "../status-cards/status-item/PersonIdentityStatus";

const ACCEPTED_DOCUMENTS_DEFAULT = [
  AcceptedDocuments.NationalIdentityCard,
  AcceptedDocuments.DrivingLicence,
  AcceptedDocuments.Passport,
];

const ACCEPTED_DOCUMENTS_COUNTRY_MAP: Record<string, AcceptedDocuments[]> = {
  [CountryCodes.GB]: [
    AcceptedDocuments.DrivingLicence,
    AcceptedDocuments.Passport,
  ],
  [CountryCodes.IE]: [
    AcceptedDocuments.DrivingLicence,
    AcceptedDocuments.Passport,
  ],
  [CountryCodes.FR]: [
    AcceptedDocuments.DrivingLicence,
    AcceptedDocuments.NationalIdentityCard,
    AcceptedDocuments.Passport,
  ],
  [CountryCodes.DE]: [
    AcceptedDocuments.DrivingLicence,
    AcceptedDocuments.Passport,
    AcceptedDocuments.NationalIdentityCard,
  ],
  [CountryCodes.ES]: [
    AcceptedDocuments.DrivingLicence,
    AcceptedDocuments.NationalIdentityCard,
    AcceptedDocuments.Passport,
  ],
  [CountryCodes.BE]: [
    AcceptedDocuments.DrivingLicence,
    AcceptedDocuments.NationalIdentityCard,
    AcceptedDocuments.Passport,
  ],
  [CountryCodes.NL]: [
    AcceptedDocuments.DrivingLicence,
    AcceptedDocuments.NationalIdentityCard,
    AcceptedDocuments.Passport,
  ],
  [CountryCodes.SV]: [
    AcceptedDocuments.NationalIdentityCard,
    AcceptedDocuments.Passport,
  ],
  [CountryCodes.SI]: [
    AcceptedDocuments.DrivingLicence,
    AcceptedDocuments.NationalIdentityCard,
    AcceptedDocuments.Passport,
  ],
  [CountryCodes.FI]: [
    AcceptedDocuments.DrivingLicence,
    AcceptedDocuments.NationalIdentityCard,
    AcceptedDocuments.Passport,
  ],
  [CountryCodes.NZ]: [AcceptedDocuments.Passport],
};

export const PersonIdentityConfig = {
  acceptedDocuments: (geo: CountryCodes) =>
    ACCEPTED_DOCUMENTS_COUNTRY_MAP[geo] || ACCEPTED_DOCUMENTS_DEFAULT,

  statusComponent: PersonIdentityStatus,

  actionComponent: PersonIdentityCard,
};
