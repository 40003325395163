import { UploadedDocumentType } from "@gocardless/api/dashboard/types";
import {
  Box,
  Button,
  ButtonVariant,
  ColorPreset,
  Form,
  H4,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { TrustConfirmationDocumentsRequirements } from "src/components/routes/Setup/optimised-verification-status/required-actions/trust-confirmation/TrustConfirmationDocumentsRequirements";
import { useForm } from "react-hook-form";

import { HavingTroubleDialog } from "../../common/HavingTroubleDialog";
import { AcceptedDocuments } from "../../common/AcceptedDocuments";
import { useUploadDocuments } from "../../required-documents/useUploadDocuments";
import { RequiredActionCardProps } from "../RequiredActionCard";
import FormError from "../../common/FormError";
import { UploadDocOnlyFormValues } from "../../constants/DocumentRequirement";
import { useUploadDocValidation } from "../../hooks/useUploadDocValidations";
import { ControlledFileUpload } from "../../common/ControlledFileUpload";

export const TrustConfirmationUpload: React.FC<RequiredActionCardProps> = ({
  creditorId,
  verificationId,
  acceptedDocuments,
  documentRequirements,
}) => {
  const { uploading, uploadDocuments } = useUploadDocuments(
    creditorId,
    verificationId
  );

  const { validateFilesMaxOnly } = useUploadDocValidation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UploadDocOnlyFormValues>({
    defaultValues: {
      upload_docs: [],
    },
  });

  const uploadSelectedDocuments = async (value: UploadDocOnlyFormValues) => {
    await uploadDocuments(value.upload_docs, UploadedDocumentType.TrustDeed);
  };

  return (
    <Form onSubmit={handleSubmit(uploadSelectedDocuments)}>
      <AcceptedDocuments multiple documents={acceptedDocuments} />

      {documentRequirements && (
        <Box
          spaceAbove={1}
          gutterV={1.5}
          gutterH={1.5}
          borderColor={ColorPreset.BorderOnLight_04}
          borderRadius={1}
          borderWidth={1}
        >
          <H4 preset={TypePreset.Heading_01}>
            <Trans>The document provided must clearly show:</Trans>
          </H4>

          <Box spaceAbove={1}>
            <TrustConfirmationDocumentsRequirements
              requirements={documentRequirements}
            />
          </Box>
        </Box>
      )}

      <Box spaceAbove={1}>
        <ControlledFileUpload
          required
          name="upload_docs"
          control={control}
          uploading={uploading}
          validationRules={validateFilesMaxOnly}
        />
      </Box>
      {errors.upload_docs?.message ? (
        <FormError errorMessage={errors.upload_docs?.message} />
      ) : null}
      <Box spaceAbove={2}>
        <Button
          type="submit"
          variant={ButtonVariant.PrimaryOnLight}
          disabled={uploading}
        >
          <Trans>Upload</Trans>
        </Button>
      </Box>
      <HavingTroubleDialog sourceComponent={TrustConfirmationUpload.name} />
    </Form>
  );
};
