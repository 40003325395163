import {
  Box,
  ButtonVariant,
  Dialog,
  Glyph,
  IconButton,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { SdkInitMethod } from "onfido-sdk-ui";
import { FC, useEffect, useRef } from "react";
import { ONFIDO_CONTAINER_ID } from "src/components/routes/SetupUser/Verify/consts";

import { useOnfido } from "./useOnfido";

interface VerifyWithOnfidoPopupProps {
  creditorId: string;
  verificationId: string;
  onfidoToken: string;
  onfido: { init: SdkInitMethod };
  updateRequiredDocuments: () => Promise<void>;
  onClose: () => void;
  onOnfidoError: () => void;
}

export const VerifyWithOnfidoPopup: FC<VerifyWithOnfidoPopupProps> = ({
  creditorId,
  verificationId,
  onfidoToken,
  onfido,
  updateRequiredDocuments,
  onClose,
  onOnfidoError,
}) => {
  const onSuccessfulUpload = async () => {
    await updateRequiredDocuments();
    onClose();
  };

  const { initOnfido } = useOnfido(
    onfidoToken,
    onfido,
    creditorId,
    verificationId,
    onSuccessfulUpload,
    onOnfidoError
  );

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (containerRef.current) {
      initOnfido();
    }
  }, [initOnfido]);

  return (
    <Dialog
      open
      bodyGutterH={0}
      bodyGutterV={0}
      data-testid="verify-with-onfido"
    >
      <Box
        minHeight={600}
        css={{ background: "#FCFCFD" }}
        className="fs-exclude"
      >
        <div id={ONFIDO_CONTAINER_ID} ref={containerRef} />
      </Box>
      <IconButton
        icon={Glyph.Close}
        label={<Trans>Close</Trans>}
        variant={ButtonVariant.TextOnLight}
        onClick={onClose}
        css={{ position: "absolute", top: 8, right: 8 }}
      />
    </Dialog>
  );
};
