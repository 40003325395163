import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";

import { RequiredDocument } from "../required-documents/useRequiredDocuments";

interface BankTimes {
  "transferwise-GBP": string;
  "transferwise-AUD": string;
  "BARC-GBP": string;
  "transferwise-EUR": string;
  "transferwise-NZD": string;
  Other: string;
}

export const bankVerificationWaitingTime = (
  requiredDocument: RequiredDocument
): string => {
  const minutes = i18n._(
    t({
      message: "minutes",
    })
  );
  const hours = i18n._(t({ message: "hours" }));
  const hour = i18n._(t({ message: "hour" }));

  const times = {
    "transferwise-GBP": `${5} ${minutes}`,
    "transferwise-AUD": `${7} ${minutes}`,
    "BARC-GBP": `${14} ${hours}`,
    "transferwise-EUR": `${3} ${hours}`,
    "transferwise-NZD": `${1} ${hour}`,
    Other: `${40} ${hours}`,
  };
  const currency = requiredDocument?.details?.currency;
  const provider = requiredDocument?.details?.provider;

  const key = `${provider}-${currency}` as keyof BankTimes;
  return times[key] || times.Other;
};
