import { CountryCodes } from "src/common/country";

import { CompanyUBOsCard } from "../required-actions/company-ubos/CompanyUBOsCard";
import { AcceptedDocuments } from "../../common/constants/AcceptedDocuments";
import { CompanyUBOsStatus } from "../status-cards/status-item/CompanyUBOsStatus";
import { DocumentRequirement } from "../constants/DocumentRequirement";

export const ACCEPTED_DOCUMENTS_DEFAULT = [
  AcceptedDocuments.CompanyAnnualReturn,
  AcceptedDocuments.RecentAnnualReport,
  AcceptedDocuments.CompanyMemorandumArticlesOfAssociation,
  AcceptedDocuments.LetterFromLegalFirm,
];

export const DOCUMENT_REQUIREMENTS_DEFAULT = [
  DocumentRequirement.LegalEntityName,
  DocumentRequirement.RegistrationNumber,
  DocumentRequirement.RegisteredAddress,
  DocumentRequirement.CurrentUltimateBeneficialOwners,
];

export const CompanyUBOsConfig = {
  acceptedDocuments: (_geo: CountryCodes) => ACCEPTED_DOCUMENTS_DEFAULT,

  documentRequirements: (_geo: CountryCodes) => DOCUMENT_REQUIREMENTS_DEFAULT,

  statusComponent: CompanyUBOsStatus,

  actionComponent: CompanyUBOsCard,
};
