import {
  ButtonGutter,
  ButtonSize,
  ButtonVariant,
  ColorPreset,
  DD,
  DL,
  DLLayout,
  DT,
  FontWeight,
  Glyph,
  IconButton,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { ReactNode, useState } from "react";

import { useSetupData } from "../../../common/hooks/useSetupData";
import { getBankAccountHolderDetails } from "../../helpers/getBankAccountHolderDetails";

import { BankAccountHolderDialog } from "./BankAccountHolderDialog";

const BoldText = ({ children }: { children: ReactNode }) => (
  <Text
    weight={FontWeight.SemiBold}
    preset={TypePreset.Body_01}
    color={ColorPreset.TextOnLight_01}
  >
    {children}
  </Text>
);

export const BankVerificationDocumentsRequirements = ({
  accountNumberEnding: anEnding,
}: {
  accountNumberEnding: string;
}) => {
  const { creditorDetails } = useSetupData();

  const [renderDialog, setRenderDialog] = useState(false);

  if (
    !creditorDetails ||
    !creditorDetails.creditor_type ||
    !creditorDetails.detail
  ) {
    return null;
  }

  const bankAccountHolderDetails = getBankAccountHolderDetails(
    creditorDetails.creditor_type,
    creditorDetails.detail
  );
  const {
    isIndividual,
    creditorName: cName,
    personName: pName,
    tradingName: tName,
  } = bankAccountHolderDetails;

  const creditorName = <Text className="fs-mask">{cName}</Text>;
  const personName = <Text className="fs-mask">{pName}</Text>;
  const tradingName = <Text className="fs-mask">{tName}</Text>;
  const accountNumberEnding = <Text className="fs-mask">{anEnding}</Text>;
  const months = 3;

  return (
    <>
      <DL layout={DLLayout.RowFixedTerm}>
        <DT>
          <Text
            weight={FontWeight.Normal}
            preset={TypePreset.Body_01}
            color={ColorPreset.TextOnLight_01}
          >
            <Trans>Account name</Trans>
          </Text>
        </DT>

        <DD>
          {isIndividual ? (
            <>
              <BoldText>{personName}</BoldText>
              {tradingName && (
                <>
                  <Text
                    weight={FontWeight.Normal}
                    preset={TypePreset.Body_01}
                    color={ColorPreset.TextOnLight_01}
                  >
                    {" "}
                    <Trans>or</Trans>{" "}
                  </Text>
                  <BoldText>{tradingName}</BoldText>
                </>
              )}
            </>
          ) : (
            <BoldText>{creditorName}</BoldText>
          )}

          <IconButton
            icon={Glyph.Tooltip}
            size={{
              gutters: ButtonGutter.Sm,
              base: ButtonSize.Sm,
            }}
            variant={ButtonVariant.TextOnLight}
            style={{
              verticalAlign: "middle",
              marginTop: -8,
              marginBottom: -8,
            }}
            label="bank account holder hint"
            onClick={() => setRenderDialog(true)}
          />
        </DD>

        <DT>
          <Text
            weight={FontWeight.Normal}
            preset={TypePreset.Body_01}
            color={ColorPreset.TextOnLight_01}
          >
            <Trans>Account number</Trans>
          </Text>
        </DT>
        <DD>
          <BoldText>{accountNumberEnding}</BoldText>
        </DD>

        <DT>
          <Text
            weight={FontWeight.Normal}
            preset={TypePreset.Body_01}
            color={ColorPreset.TextOnLight_01}
          >
            <Trans>Date of issue</Trans>
          </Text>
        </DT>
        <DD>
          <BoldText>
            <Trans>Within the last {months} months</Trans>
          </BoldText>
        </DD>
      </DL>

      {renderDialog && (
        <BankAccountHolderDialog
          bankAccountHolderDetails={bankAccountHolderDetails}
          onClose={() => setRenderDialog(false)}
        />
      )}
    </>
  );
};
