import { Controller, FieldValues } from "react-hook-form";

import { useUploadDocValidation } from "../hooks/useUploadDocValidations";

import { ChooseFiles } from "./ChooseFiles";

interface ControlledFileUploadProps extends FieldValues {
  name: string;
  uploading: boolean;
  validationRules: (filesArray: File[]) => string | true;
  required: boolean;
  multiple?: boolean;
}

export const ControlledFileUpload = ({
  control,
  uploading,
  validationRules,
  required,
  multiple = true,
  name,
}: ControlledFileUploadProps) => {
  const { uploadDocValidationMessages } = useUploadDocValidation();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required
          ? uploadDocValidationMessages.documentRequired
          : false,
        validate: validationRules,
      }}
      render={({ field }) => (
        <ChooseFiles
          multiple={multiple}
          files={field.value}
          onChange={field.onChange}
          uploading={uploading}
        />
      )}
    />
  );
};
