import { Box, Button, ButtonVariant } from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useFormContext } from "react-hook-form";

import { ControlledFileUpload } from "../../common/ControlledFileUpload";
import FormError from "../../common/FormError";
import { useUploadDocValidation } from "../../hooks/useUploadDocValidations";
import { IdentityFormValues } from "../../constants/DocumentRequirement";

interface ManualDocumentUploadProps {
  uploading: boolean;
}

export const ManualDocumentUpload = ({
  uploading,
}: ManualDocumentUploadProps) => {
  const { validateFilesMaxOnly } = useUploadDocValidation();

  const {
    control,
    formState: { errors },
  } = useFormContext<IdentityFormValues>();

  return (
    <Box spaceAbove={1.5}>
      <ControlledFileUpload
        required
        name="upload_docs"
        control={control}
        uploading={uploading}
        validationRules={validateFilesMaxOnly}
      />

      {errors.upload_docs?.message ? (
        <FormError errorMessage={errors.upload_docs.message} />
      ) : null}
      <Box spaceAbove={1.5}>
        <Button
          type="submit"
          variant={ButtonVariant.PrimaryOnLight}
          disabled={uploading}
        >
          <Trans>Upload</Trans>
        </Button>
      </Box>
    </Box>
  );
};
