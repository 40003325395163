import { AcceptedDocuments } from "src/components/routes/Setup/common/constants/AcceptedDocuments";

import { HighRiskVerificationCard } from "../required-actions/high-risk/HighRiskVerificationCard";
import { HighRiskVerificationStatus } from "../status-cards/status-item/HighRiskVerificationStatus";

export const COMPANY_OR_PARTNERSHIP_DOCUMENTS = [
  [
    AcceptedDocuments.SignedStatuses,
    AcceptedDocuments.ShareOwnershipStructure,
    AcceptedDocuments.AnnualReturn,
    AcceptedDocuments.MemorandumOfAssociation,
    AcceptedDocuments.ArticlesOfAssociation,
  ],
  [
    AcceptedDocuments.FiledAccounts,
    AcceptedDocuments.TaxReturn,
    AcceptedDocuments.FinancialBalance,
  ],
];
export const CHARITY_DOCUMENTS = [
  [
    AcceptedDocuments.SignedStatuses,
    AcceptedDocuments.AnnualReturnOrReport,
    AcceptedDocuments.ShareOwnershipStructure,
    AcceptedDocuments.MemorandumOfAssociation,
  ],
  [
    AcceptedDocuments.MostRecentAnnualReportOrAccounts,
    AcceptedDocuments.TaxReturn,
    AcceptedDocuments.FinancialBalance,
  ],
];
export const INDIVIDUAL_DOCUMENTS = [
  [
    AcceptedDocuments.MostRecentAnnualAccounts,
    AcceptedDocuments.TaxReturn,
    AcceptedDocuments.FinancialBalance,
  ],
];

export const HighRiskConfig = {
  statusComponent: HighRiskVerificationStatus,
  actionComponent: HighRiskVerificationCard,
};
