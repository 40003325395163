import {
  ColorPreset,
  DL,
  DLLayout,
  DT,
  FontWeight,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { useLingui } from "@lingui/react";

import {
  DocumentRequirement,
  DocumentRequirementsNameMap,
} from "../../constants/DocumentRequirement";

export const GermanPartnersDocumentRequirements = ({
  requirements,
}: {
  requirements: DocumentRequirement[];
}) => {
  const { i18n } = useLingui();
  const DOCUMENT_REQUIREMENTS_NAME_MAP = DocumentRequirementsNameMap(i18n);

  return (
    <DL layout={DLLayout.RowFixedTerm}>
      {requirements.map((requirement) => (
        <DT
          key={requirement}
          style={requirement ? {} : { gridColumn: "span 3" }}
        >
          <Text
            weight={FontWeight.Normal}
            preset={TypePreset.Body_01}
            color={ColorPreset.TextOnLight_01}
          >
            {DOCUMENT_REQUIREMENTS_NAME_MAP[requirement]}
          </Text>
        </DT>
      ))}
    </DL>
  );
};
