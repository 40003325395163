import { RequiredActionsStatus } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { useMemo } from "react";
import { Text } from "@gocardless/flux-react";

import { RequiredDocument } from "../../required-documents/useRequiredDocuments";

import { StatusItem } from "./StatusItem";

export const RequiredDocumentStatus: React.FC<{
  requiredDocument: RequiredDocument;
}> = ({ requiredDocument }) => {
  const { title } = useMemo(() => {
    const { entity } = requiredDocument;
    const entityTitle = <Text className="fs-mask">{entity.name || ""}</Text>;

    switch (requiredDocument.status) {
      case RequiredActionsStatus.Successful:
        return {
          title: <Trans>We&apos;ve verified details for {entityTitle}</Trans>,
        };
      case RequiredActionsStatus.Pending:
      case RequiredActionsStatus.PendingResponse:
        return {
          title: <Trans>Verify details for {entityTitle}</Trans>,
        };
      default:
        return {
          title: <Trans>We&apos;re verifying details for {entityTitle}</Trans>,
        };
    }
  }, [requiredDocument]);

  return (
    <StatusItem
      requiredActionId={requiredDocument.verificationId}
      title={title}
      status={requiredDocument.status}
      lastTransitionTime={requiredDocument.lastTransitionTime}
    />
  );
};
