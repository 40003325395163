import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";

interface MinSpecificConditions {
  condition: boolean;
  minFilesRequired: number;
}

interface MaxSpecificConditions {
  condition: boolean;
  maxFilesRequired: number;
}

export function useUploadDocValidation(
  minSpecificConditions: MinSpecificConditions = {
    condition: false,
    minFilesRequired: 1,
  },
  maxSpecificConditions: MaxSpecificConditions = {
    condition: true,
    maxFilesRequired: 5,
  }
) {
  const { i18n } = useLingui();
  const file = i18n._(t`file`);
  const files = i18n._(t`files`);

  const uploadDocValidationMessages = {
    documentRequired: i18n._(t`Please upload required documents to continue`),

    confirmInfo: i18n._(
      t`Please confirm the document shows the above information`
    ),
    confirmNoPhotoCopy: i18n._(
      `Please confirm proof of identity cannot be a photocopy`
    ),
  };

  const validateFilesMaxOnly = (filesArray: File[]) => {
    if (
      filesArray.length > maxSpecificConditions.maxFilesRequired &&
      maxSpecificConditions.condition
    ) {
      return i18n._(
        t`You can upload a maximum of ${
          maxSpecificConditions.maxFilesRequired
        } ${maxSpecificConditions.maxFilesRequired === 1 ? file : files}`
      );
    }
    return true; // Valid
  };

  const validateFilesMinAndMaxWithSpecificCondition = (filesArray: File[]) => {
    if (
      filesArray.length < minSpecificConditions.minFilesRequired &&
      minSpecificConditions.condition
    ) {
      return i18n._(
        t`You must upload at least ${minSpecificConditions.minFilesRequired} ${
          minSpecificConditions.minFilesRequired === 1 ? file : files
        }`
      );
    }
    if (
      filesArray.length > maxSpecificConditions.maxFilesRequired &&
      maxSpecificConditions.condition
    ) {
      return i18n._(
        t`You can upload a maximum of ${
          maxSpecificConditions.maxFilesRequired
        } ${maxSpecificConditions.maxFilesRequired === 1 ? file : files}`
      );
    }
    return true; // Valid
  };

  return {
    uploadDocValidationMessages,
    validateFilesMaxOnly,
    validateFilesMinAndMaxWithSpecificCondition,
  };
}
