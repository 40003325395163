import { RequiredActionsStatus } from "@gocardless/api/dashboard/types";
import { useMemo } from "react";
import { Trans } from "@lingui/macro";
import { Text } from "@gocardless/flux-react";

import { RequiredDocument } from "../../required-documents/useRequiredDocuments";
import { getPersonName } from "../../helpers/getPersonName";

import { StatusItem } from "./StatusItem";

export const AddressConfirmationStatus: React.FC<{
  requiredDocument: RequiredDocument;
}> = ({ requiredDocument }) => {
  const { title } = useMemo(() => {
    const { entity } = requiredDocument;
    const entityTitle = (
      <Text className="fs-mask">{getPersonName(entity)}</Text>
    );

    switch (requiredDocument.status) {
      case RequiredActionsStatus.Successful:
        return {
          title: (
            <Trans>We&apos;ve verified address details for {entityTitle}</Trans>
          ),
        };
      case RequiredActionsStatus.Pending:
      case RequiredActionsStatus.PendingResponse:
        return {
          title: <Trans>Verify address details for {entityTitle}</Trans>,
        };
      default:
        return {
          title: (
            <Trans>
              We&apos;re verifying address details for {entityTitle}
            </Trans>
          ),
        };
    }
  }, [requiredDocument]);

  return (
    <StatusItem
      requiredActionId={requiredDocument.verificationId}
      title={title}
      status={requiredDocument.status}
      lastTransitionTime={requiredDocument.lastTransitionTime}
    />
  );
};
