import { CountryCodes } from "src/common/country";
import { PartnershipType } from "@gocardless/api/dashboard/types";

import { AcceptedDocuments } from "../../common/constants/AcceptedDocuments";
import { DocumentRequirement } from "../constants/DocumentRequirement";
import { RequiredDocumentStatus } from "../status-cards/status-item/RequiredDocumentStatus";
import { PartnershipIdentityCard } from "../required-actions/partnership-identity/PartnershipIdentityCard";

export const AU_DOCUMENTS = [
  AcceptedDocuments.FullSignedPartnershipAgreement,
  AcceptedDocuments.RecentAsicFiling,
];

export const CA_DOCUMENTS = [
  AcceptedDocuments.SignedPartnershipDeed,
  AcceptedDocuments.PartnershipEntryOnAProfessionalAssociationMembershipDirectory,
];

export const DE_GBR_DOCUMENTS = [AcceptedDocuments.CopyOfGewerbeanmeldung];

export const DE_DEFAULT_DOCUMENTS = [
  AcceptedDocuments.CompanyExtractFromOfficialCompanyRegister,
];

export const DOCUMENT_REQUIREMENTS_DEFAULT = [
  DocumentRequirement.OfficialPartnershipName,
  DocumentRequirement.RegisteredAddress,
];

export const DE_GBR_DOCUMENT_REQUIREMENTS = [
  DocumentRequirement.OfficialPartnershipName,
  DocumentRequirement.RegisteredAddress,
  DocumentRequirement.PartnerNameAndDetails,
  DocumentRequirement.NumberOfPartners,
];

export const DE_DOCUMENT_REQUIREMENTS_DEFAULT = [
  DocumentRequirement.OfficialPartnershipName,
  DocumentRequirement.EntityType,
  DocumentRequirement.RegistrationNumber,
  DocumentRequirement.RegisteredAddress,
  DocumentRequirement.NamesOfAllPartners,
];

export const DE_PARTNERS_DOCUMENT_REQUIREMENTS_DEFAULT = [
  DocumentRequirement.LegalEntityName,
  DocumentRequirement.EntityType,
  DocumentRequirement.RegistrationNumber,
  DocumentRequirement.RegisteredAddress,
  DocumentRequirement.LegalRepresentatives,
];

export const getAcceptedDocuments = (
  geo: CountryCodes,
  creditorSubtype: PartnershipType
) => {
  switch (geo) {
    case CountryCodes.AU:
      return AU_DOCUMENTS;
    case CountryCodes.CA:
      return CA_DOCUMENTS;
    case CountryCodes.DE:
      return isGbrPartnership(creditorSubtype)
        ? DE_GBR_DOCUMENTS
        : DE_DEFAULT_DOCUMENTS;
    default:
      return [];
  }
};

export const getDocumentRequirements = (
  geo: CountryCodes,
  creditorSubtype: PartnershipType
) => {
  switch (geo) {
    case CountryCodes.AU:
    case CountryCodes.CA:
      return DOCUMENT_REQUIREMENTS_DEFAULT;
    case CountryCodes.DE:
      return isGbrPartnership(creditorSubtype)
        ? DE_GBR_DOCUMENT_REQUIREMENTS
        : DE_DOCUMENT_REQUIREMENTS_DEFAULT;
    default:
      return [];
  }
};

const isGbrPartnership = (creditorSubtype: PartnershipType) =>
  creditorSubtype === PartnershipType.Gbr;

export const PartnershipIdentityConfig = {
  statusComponent: RequiredDocumentStatus,
  actionComponent: PartnershipIdentityCard,
};
